import { Button, Accordion, Card, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import cart from "../../assets/images/cart.png";
import baggage from "../../assets/images/baggage.png";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { QuestionCircleFill } from "react-bootstrap-icons";
import pricing from "../../assets/json/pricing.json";
import _update from "lodash/update";
import axios from "axios";
import FormData from "form-data";
import getPrice from "../Utils/PriceService";
import getTotalPrice from "../Utils/TotalPriceService";

function StepFour({ nextStep, prevStep }: any) {
  const { t, i18n } = useTranslation();
  // const [show, setShow] = useState(false);
  const [travelWithCoro, setTravelWithCoro] = useState("including");
  const [baggage, setBaggage] = useState("including");
  const [retrievalCheck, setRetrievalCheck] = useState(true);
  const [retrievalModal, setRetrievalModal] = useState(false);
  const [liabilityCheck, setLiabilityCheck] = useState(true);
  const [checked, setChecked] = useState(false);
  const [totalPrice, setTotalPrice] = useState("");
  const [fields, setFields] = useState([]);
  const [priceData, setPriceData] = useState("");
  const [policyPrice, setPolicyPrice] = useState("");

  const submitForm = (e: any) => {
    e.preventDefault();
    const totalPriceArr: number[] = [];

    //console.log(travelWithCoro);
    // const totalPrice = totalPriceArr.reduce((a, b) => {
    //   return a + b;
    // }, 0);
    const dataForm = new FormData();
    dataForm.append("item", "corona");
    getPrice(dataForm, `corona`).then((data: any) => {
      nextStep();
    });
    //sessionStorage.setItem("totalPrice", JSON.stringify(totalPrice));
  };

  const changeCancel = (e: any) => {
    setChecked(!checked);
    // setTravelWithCoro(
    //   travelWithCoro == "including" ? "notincluding" : "including"
    // );
    //console.log(fields);
    if (checked) {
      setTravelWithCoro("including");
      sessionStorage.setItem("travelwithcoro", "including");
      const newFieldsArr: any[] = [];
      fields.map((_e: any, i: any) => {
        const years = _e.age;
        const { min: fmin, max: fmax, price: fprice } = pricing[1].corona.first;
        const {
          min: smin,
          max: smax,
          price: sprice,
        } = pricing[1].corona.second;
        if (years >= fmin && years <= fmax) {
          const newObj = _update(_e, "price", (n) => {
            return n + fprice;
          });
          newFieldsArr.push(newObj);
        }
        if (years >= smin && years <= smax) {
          const newObj = _update(_e, "price", (n) => {
            return n + sprice;
          });
          newFieldsArr.push(newObj);
        }
      });

      //setFields(newFields);
      sessionStorage.setItem("fields", JSON.stringify(newFieldsArr));
    } else {
      setTravelWithCoro("notincluding");
      sessionStorage.setItem("travelwithcoro", "notincluding");
      const newFieldsArr: any[] = [];
      fields.map((_e: any, i: any) => {
        const years = _e.age;
        const price = _e.price;
        const { min: fmin, max: fmax, price: fprice } = pricing[1].corona.first;
        const { price: afprice } = pricing[0].ageGroup.first;
        const {
          min: smin,
          max: smax,
          price: sprice,
        } = pricing[1].corona.second;
        if (years >= fmin && years <= fmax && price != afprice) {
          const newObj = _update(_e, "price", (n) => {
            return n - fprice;
          });
          newFieldsArr.push(newObj);
        }
        if (years >= smin && years <= smax && price != afprice) {
          const newObj = _update(_e, "price", (n) => {
            return n - sprice;
          });
          newFieldsArr.push(newObj);
        }
      });

      //setFields(newFields);
      sessionStorage.setItem("fields", JSON.stringify(newFieldsArr));
    }
  };
  // const handleShow = () => setShow(true);
  const handleTravelWithCoro = (e: any) => {
    setTravelWithCoro(e.target.value);
    sessionStorage.setItem("travelwithcoro", e.target.value);
    if (e.target.value == "notincluding") {
      setChecked(true);
    } else {
      setChecked(false);
      fields.map((_e: any, i: any) => {
        const years = _e.age;

        const { min: fmin, max: fmax, price: fprice } = pricing[1].corona.first;
        const {
          min: smin,
          max: smax,
          price: sprice,
        } = pricing[1].corona.second;
        if (years >= fmin && years <= fmax) {
          _e.price = fprice + _e.price;
        }

        if (years >= smin && years <= smax) {
          _e.price = sprice + _e.price;
        }
      });
      //console.log(fields);
    }
  };
  const handleBaggage = (e: any) => {
    setBaggage(e.target.value);
    sessionStorage.setItem("baggage", e.target.value);
  };
  const handleRetrievalCheck = (e: any) => {
    setRetrievalCheck(!retrievalCheck);
    sessionStorage.setItem("retrievalCheck", JSON.stringify(!retrievalCheck));
    setRetrievalModal(!retrievalModal);
  };
  const handleliabilityCheck = (e: any) => {
    setLiabilityCheck(!liabilityCheck);
    sessionStorage.setItem("liabilityCheck", JSON.stringify(!liabilityCheck));
  };
  const handleRetrivalModal = (e: any) => {
    setRetrievalModal(!retrievalModal);
    //setRetrievalCheck(!retrievalCheck);
  };
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("fields") || "");
    setFields(data);
    if (sessionStorage.getItem("travelwithcoro") != null) {
      const twc = sessionStorage.getItem("travelwithcoro") || "";
      setTravelWithCoro(twc);
      if (twc == "notincluding") {
        setChecked(true);
      } else {
        setChecked(false);
        const newFieldsArr = data.map((_e: any, i: any) => {
          const years = _e.age;

          const {
            min: fmin,
            max: fmax,
            price: fprice,
          } = pricing[1].corona.first;
          const {
            min: smin,
            max: smax,
            price: sprice,
          } = pricing[1].corona.second;
          if (years >= fmin && years <= fmax) {
            _e.price = fprice + _e.price;
          }

          if (years >= smin && years <= smax) {
            _e.price = sprice + _e.price;
          }
        });
        //sessionStorage.setItem("fields", JSON.stringify(newFieldsArr));
        console.table(newFieldsArr);
      }
    }
    sessionStorage.setItem("travelwithcoro", "including");
    sessionStorage.setItem("priceData", priceData);
    // const data = JSON.parse(sessionStorage.getItem("fields") || "");
    // setFields(data);
    if (sessionStorage.getItem("priceData") != null) {
      const pd = sessionStorage.getItem("priceData") || "";
    }
    if (sessionStorage.getItem("totalPrice") != null) {
      const tp = sessionStorage.getItem("totalPrice") || "";
      setTotalPrice(tp);
    }
    if (sessionStorage.getItem("baggage") != null) {
      const bgg = sessionStorage.getItem("baggage") || "";
      setBaggage(bgg);
    }
    if (sessionStorage.getItem("retrievalCheck") != null) {
      const rc = JSON.parse(sessionStorage.getItem("retrievalCheck") || "");
      setRetrievalCheck(rc);
    }
    if (sessionStorage.getItem("liabilityCheck") != null) {
      const lc = JSON.parse(sessionStorage.getItem("liabilityCheck") || "");
      setLiabilityCheck(lc);
    }
    setPriceData(`${getTotalPrice()}`);
  }, []);

  return (
    <form id="formbox" onSubmit={submitForm} autoComplete="new-password">
      <a href="#" onClick={prevStep} className="back">
        {t("return")}
      </a>
      {/* <div className="topprice">
        <p>$16.80 For the</p>
      </div> */}
      <div className="topsection">
        <h6>{t("yourPropsal")}</h6>
        <p className="price">${priceData}</p>
        <p>{t("entirePeriod")}</p>
      </div>
      <div className="contentbox">
        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              {t("basicCovers")}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <ReactTooltip
                  multiline={true}
                  place="top"
                  type="dark"
                  effect="float"
                />

                <QuestionCircleFill
                  data-tip={t("step_four_tooltip_1")}
                  color="#00AFD8"
                />
                <label htmlFor="">{t("medicalExp")}</label>

                <input type="checkbox" checked disabled name="" id="" />

                <br />
                <QuestionCircleFill
                  data-tip={t("step_four_tooltip_2")}
                  color="#00AFD8"
                />
                <label htmlFor="">{t("searchRetrv")}</label>

                <input
                  checked={retrievalCheck}
                  type="checkbox"
                  name="retrievalCheck"
                  id="retrievalCheck"
                  onChange={handleRetrievalCheck}
                />
                <br />
                <QuestionCircleFill
                  data-tip={t("step_four_tooltip_3")}
                  color="#00AFD8"
                />
                <label htmlFor="">{t("liabToParty")}</label>

                <input
                  checked={liabilityCheck}
                  type="checkbox"
                  name="liabilityCheck"
                  id="liabilityCheck"
                  onChange={handleliabilityCheck}
                />
                <br />
                <br />
                <br />
                <a href="#">{t("viewPolicyDetails")}</a>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <br />
        <QuestionCircleFill
          data-tip={t("step_four_tooltip_4")}
          color="#00AFD8"
        />
        <h6>{t("cancelationCorona")}</h6>
        <div className="radioSelect incRadioD" onChange={handleTravelWithCoro}>
          <div
            className={`incRadio ${
              travelWithCoro == "including" ? "incRadioActive" : ""
            } `}
          >
            <input type="radio" name="travwithcoro" id="" value="including" />
            <label htmlFor="travwithcoro">{t("including")}</label>
          </div>
          <div
            className={`incRadio ${
              travelWithCoro == "notincluding" ? "incRadioActive" : ""
            } `}
          >
            <input
              type="radio"
              name="travwithcoro"
              id=""
              value="notincluding"
            />
            <label htmlFor="travwithcoro">{t("notIncluding")}</label>
          </div>
        </div>

        <span>
          <QuestionCircleFill
            data-tip={t("step_four_tooltip_5")}
            color="#00AFD8"
          />
          <h6>{t("baggage")}</h6>
        </span>
        <span></span>
        <div className="radioSelect incRadioD" onChange={handleBaggage}>
          <div
            className={`incRadio ${
              baggage == "including" ? "incRadioActive" : ""
            } `}
          >
            <input type="radio" name="baggage" id="" value="including" />
            <label htmlFor="baggage">{t("including")}</label>
          </div>
          <div
            className={`incRadio ${
              baggage == "notincluding" ? "incRadioActive" : ""
            } `}
          >
            <input type="radio" name="baggage" id="" value="notincluding" />
            <label htmlFor="baggage">{t("notIncluding")}</label>
          </div>
        </div>

        <span className="comonCheck">
          <input
            type="checkbox"
            name="cancel"
            id=""
            checked={checked}
            onChange={changeCancel}
          />
        </span>

        <label htmlFor="">{t("cancelIncCorona")}</label>
        <QuestionCircleFill
          data-tip={t("step_four_tooltip_6")}
          color="#00AFD8"
        />
      </div>
      <input className="formsubmit" type="submit" value={`${t("continue")}`} />
      <div className="next-button-wr">
        {/* <Button onClick={nextStep}>{t("continued")}</Button> */}
      </div>
    </form>
  );
}

export default StepFour;
