import { Button, Col, Modal, Row } from "react-bootstrap";
import second from "../../assets/images/health.png";
import user from "../../assets/images/user.png";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import shield from "../../assets/images/shield.png";
import pricing from "../../assets/json/pricing.json";
import axios from "axios";
import FormData from "form-data";
import getPrice from "../Utils/PriceService";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function Second({ closeModal, setSecondDone }: any) {
  const { t } = useTranslation();
  const [dates, setDates] = useState({
    releaseDate: "",
    returnDate: "",
  });
  const finalHandleClose = () => {
    closeModal();
    setFinalShow(false);
  };
  const [checked, setChecked] = useState(false);
  const [finalshow, setFinalShow] = useState(false);
  const [fields, setFields] = useState([]);
  const [healthFields, setHealthFields] = useState([]);
  const [secondData, setSecondData] = useState("");
  const closeHealthModal = () => {
    sessionStorage.setItem("fields", JSON.stringify(fields));
    const firstArr: any[] = [];
    fields.map((e: any) =>
      // eslint-disable-next-line no-prototype-builtins
      e.hasOwnProperty("second")
        ? e.second == true
          ? firstArr.push(e.second)
          : setSecondDone(false)
        : setSecondDone(false)
    );

    const secondFormData = new FormData();
    secondFormData.append("item", "second_class");
    getPrice(secondFormData, `second`)
      .then((data: any) => {
        setSecondData(`${data}`);
      })
      .catch((error) => {
        setSecondData("");
      });

    if (firstArr.includes(true)) {
      setSecondDone(true);
      setFinalShow(true);
    }
  };

  const handleChangeInput = (id: any, event: any) => {
    //console.log(event.target);
    // setChecked(!checked);
    const newInputFields = fields.map((i: any, idx) => {
      // console.log(i);
      if (id === idx) {
        i[event.target.name] = !i.second;
        const years = i.age;

        const {
          min: fmin,
          max: fmax,
          price: fprice,
        } = pricing[2].additional.first;
        const {
          min: smin,
          max: smax,
          price: sprice,
        } = pricing[2].additional.second;
        if (years >= fmin && years <= fmax) {
          i["price"] = fprice + i.price;
        }

        if (years >= smin && years <= smax) {
          i["price"] = sprice + i.price;
        }
      }
      return i;
    });

    setFields(newInputFields);

    console.log(fields);
    console.log(fields[0].second);
    if (fields[0].second == true) {
      sessionStorage.setItem("second_class", "true");
    }
    //console.log(newInputFields);
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].second) {
        fields[i].secondValue = sessionStorage.getItem("secondclassvalue");
      } else {
        fields[i].secondValue = 0;
      }
    }
    sessionStorage.setItem("fields", JSON.stringify(fields));
  };
  useEffect(() => {
    const secondFormData = new FormData();
    secondFormData.append("item", "second_class");
    axios({
      method: "POST",
      url: "https://travelins.ajjawi.com/backoffice/api/pricing/",
      headers: { "Content-Type": "multipart/form-data" },
      data: secondFormData,
    })
      .then(function (response) {
        console.log(response);
        setSecondData(response.data[0].others_price);
        sessionStorage.setItem(
          "secondclassvalue",
          response.data[0].others_price
        );
      })
      .catch(function (error) {
        console.log(error);
      });
    const data = JSON.parse(sessionStorage.getItem("fields") || "");

    const nf = data.map((e: any) =>
      // eslint-disable-next-line no-prototype-builtins
      e.hasOwnProperty("second") ? e : Object.assign(e, { second: false })
    );
    setFields(nf);

    const returnDate = sessionStorage.getItem("returndate");
    const releasedate = sessionStorage.getItem("releasedate");
    setDates({
      releaseDate: releasedate,
      returnDate: returnDate,
    });
  }, []);
  return (
    <>
      <Modal.Body>
        <div className="firstlogo">
          <img src={second} alt="" />
          <div className="topsection">
            <h6>{t("secondClass")}</h6>
            <p>{t("secondText")}</p>
          </div>
          <div className="date">
            {moment(dates.releaseDate).format("DD/MM/YYYY")} -{" "}
            {moment(dates.returnDate).format("DD/MM/YYYY")}
          </div>
          <h6>Who to trust?</h6>
          <div className="insured">
            <Row style={{ direction: "rtl" }}>
              {fields.map((e: any, i: number) => (
                <Col className="insured" key={i}>
                  <h6>
                    {t("insured")} {i + 1}
                  </h6>
                  <p>{e.date}</p>
                  <div className="hcheckbox">
                    <input
                      type="checkbox"
                      name="second"
                      id=""
                      defaultChecked={checked}
                      onChange={(event: any) => {
                        handleChangeInput(i, event);
                      }}
                    />
                    <div
                      className="imgBordr"
                      style={
                        e.second
                          ? { border: "2px solid red" }
                          : { border: "1px solid #ccc" }
                      }
                    >
                      <img src={user} alt="" />
                    </div>
                  </div>
                  <p>
                    {t("forEntire")}
                    <br />
                    {t("period")}
                  </p>
                </Col>
              ))}
            </Row>
          </div>
          <a href="#" onClick={closeModal}>
            {t("understoodBut")}
          </a>
          <br />
          <Button onClick={closeHealthModal}>{t("addCover")}</Button>
          <Modal
            show={finalshow}
            onHide={finalHandleClose}
            className="stepDmodal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <img src={shield} alt="" />
              <h6>{t("coverAdded")}</h6>
              <p>{t("policyUpdated")}</p>
              <p>$ {secondData}</p>
            </Modal.Body>
          </Modal>
        </div>
      </Modal.Body>
    </>
  );
}

export default Second;
