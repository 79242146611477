import { Button } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { useState, useEffect } from "react";
import moment from "moment";
import he from "date-fns/locale/he";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import getPrice from "../Utils/PriceService";

export default function StepThree({ nextStep, prevStep }: any) {
  const { t, i18n } = useTranslation();
  const [noOfDays, setNoOfDays] = useState(1);
  const [releaseDate, setReleaseDate] = useState(new Date());
  const [returnDate, setReturnDate] = useState(new Date());
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: "selection",
    },
  ]);

  const handleSelect = (date: any) => {
    setState([date.selection]);
    setReleaseDate(date.selection.startDate);
    setReturnDate(date.selection.endDate);
    const a = moment(date.selection.startDate);
    const b = moment(date.selection.endDate);
    const c = b.diff(a, "days");
    setNoOfDays(c + 1);
    sessionStorage.setItem("noOfDays", JSON.stringify(c + 1));
    sessionStorage.setItem("returndate", date.selection.endDate);
    sessionStorage.setItem("releasedate", date.selection.startDate);
  };

  const handleReleaseDate = (e: any) => {
    setState([
      {
        startDate: e,
        endDate: returnDate,
        key: "selection",
      },
    ]);

    const a = moment(e);
    const b = moment(returnDate);
    const c = b.diff(a, "days");
    setNoOfDays(c + 1);

    setReleaseDate(e);
    sessionStorage.setItem("noOfDays", JSON.stringify(c + 1));
    sessionStorage.setItem("releasedate", e);
    sessionStorage.setItem("returndate", `${returnDate}`);
  };

  const handleReturnDate = (e: any) => {
    const a = moment(releaseDate);
    const b = moment(e);
    const c = b.diff(a, "days");
    setNoOfDays(c + 1);
    setState([
      {
        startDate: releaseDate,
        endDate: e,
        key: "selection",
      },
    ]);
    setReturnDate(e);
    sessionStorage.setItem("noOfDays", JSON.stringify(c + 1));
    sessionStorage.setItem("returndate", e);
    console.log(e);

    sessionStorage.setItem("releasedate", `${releaseDate}`);
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("releasedate") != null &&
      sessionStorage.getItem("returndate") != null
    ) {
      setReleaseDate(moment(sessionStorage.getItem("releasedate")).toDate());
      setReturnDate(moment(sessionStorage.getItem("returndate")).toDate());
      setState([
        {
          startDate: moment(sessionStorage.getItem("releasedate")).toDate(),
          endDate: moment(sessionStorage.getItem("returndate")).toDate(),
          key: "selection",
        },
      ]);
    }

    if (sessionStorage.getItem("noOfDays") != null) {
      setNoOfDays(Number(sessionStorage.getItem("noOfDays")));
    }
  }, []);

  const submitForm = (e: any) => {
    e.preventDefault();
    const sameDate = moment(releaseDate).isSame(returnDate);
    if (sameDate) {
      toast.error(t("cantSame"), {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const dataForm = new FormData();
      dataForm.append("item", "basic");

      getPrice(dataForm, `basic`).then((data: any) => {
        nextStep();
      });
    }
  };

  return (
    <form id="formbox" className="thirdform" onSubmit={submitForm}>
      <ToastContainer rtl={true} />
      <a href="#" onClick={prevStep} className="back">
        {t("return")}
      </a>
      <div className="contentbox2">
        <div className="return-release">
          <div className="return">{t("returnDate")}</div>
          <div className="release">{t("releaseDate")}</div>
        </div>
        <div
          className="return-release-input"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div className="return-input">
            <DatePicker
              selected={returnDate}
              onChange={handleReturnDate}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="release-input">
            <DatePicker
              selected={releaseDate}
              onChange={handleReleaseDate}
              dateFormat="dd/MM/yyyy"
            />
          </div>
        </div>
        <DateRangePicker
          onChange={handleSelect}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={state}
          minDate={addDays(new Date(), -0)}
          scroll={{ enabled: true }}
          direction="vertical"
          editableDateInputs={true}
          showMonthAndYearPickers={false}
          locale={he}
        />
        <p style={{ textAlign: "center" }}>
          {t("total")} <strong>{noOfDays}</strong> {t("daysForIns")}
        </p>
      </div>
      <div className="next-button-wr">
        <input
          className="formsubmit"
          type="submit"
          value={`${t("continue")}`}
        />
      </div>
    </form>
  );
}
