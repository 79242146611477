/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-prototype-builtins */
import { Button, Col, Modal, Row } from "react-bootstrap";
import electronic from "../../assets/images/electronic.png";
import user from "../../assets/images/user.png";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import shield from "../../assets/images/shield.png";
import moment from "moment";
import { checkbox } from "./checkbox";
import pricing from "../../assets/json/pricing.json";
import axios from "axios";
import FormData from "form-data";
import getPrice from "../Utils/PriceService";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function Electronic({ closeModal, setElectronicDone }: any) {
  const { t, i18n } = useTranslation();
  const [fields, setFields] = useState([]);
  const [devices, setDevices] = useState([]);
  const [noMoreDevice, setNoMoreDevice] = useState(false);
  const [finalshow, setFinalShow] = useState(false);
  const [electronicData, setElectronicData] = useState("");
  const [dates, setDates] = useState({
    releaseDate: "",
    returnDate: "",
  });
  const [checkedState, setCheckedState] = useState(
    new Array(checkbox.length).fill(false)
  );
  const [total, setTotal] = useState(0);

  const handleOnChange = (position: number, event: any, fieldId: number) => {
    const newInputFields = fields.map((i: any, fi: any) => {
      if (fieldId === fi) {
        //setCheckedState(i.device);

        const updatedCheckedState = i.device.map((item: any, index: number) =>
          index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);

        i["device"] = updatedCheckedState;

        const totalValue = updatedCheckedState.reduce(
          (sum: number, currentState: boolean, index: any) => {
            if (currentState === true) {
              return sum + checkbox[index].value;
            }
            return sum;
          },
          0
        );

        setTotal(totalValue);

        if (totalValue >= 2) {
          i["electDone"] = true;
          const years = i.age;

          const {
            min: fmin,
            max: fmax,
            price: fprice,
          } = pricing[2].additional.first;
          const {
            min: smin,
            max: smax,
            price: sprice,
          } = pricing[2].additional.second;
          if (years >= fmin && years <= fmax) {
            i["price"] = fprice + i.price;
          }

          if (years >= smin && years <= smax) {
            i["price"] = sprice + i.price;
          }
        } else {
          i["electDone"] = false;
        }
      }
      return i;
    });
    setFields(newInputFields);
  };

  const handleChangeInput = (id: any, e: any) => {
    const newInputFields = fields.map((i: any, fi: any) => {
      // console.log(i);
      if (id === fi) {
        // i[e.target.name] = !i[e.target.name];
      }
      return i;
    });

    setFields(newInputFields);
    if (fields[0].electDone == true) {
      sessionStorage.setItem("electDone", "true");
    }
    console.log(newInputFields);
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].electDone) {
        fields[i].electronicValue = sessionStorage.getItem("electronicvalue");
      } else {
        fields[i].electronicValue = 0;
      }
    }
    sessionStorage.setItem("fields", JSON.stringify(fields));
  };
  const finalHandleClose = () => {
    closeModal();
    setFinalShow(false);
  };
  const closeElectronicsModal = () => {
    sessionStorage.setItem("fields", JSON.stringify(fields));
    const deviceDoneArr: any[] = [];
    fields.map((e: any) => {
      // eslint-disable-next-line no-prototype-builtins
      e.hasOwnProperty("electDone")
        ? e.electDone == true
          ? deviceDoneArr.push(e.electDone)
          : setElectronicDone(false)
        : setElectronicDone(false);
    });

    const ElectronicFormData = new FormData();
    ElectronicFormData.append("item", "electronic");

    getPrice(ElectronicFormData, `electDone`)
      .then((data: any) => {
        setElectronicData(`${data}`);
      })
      .catch((error) => {
        setElectronicData("");
      });

    if (deviceDoneArr.includes(true)) {
      setElectronicDone(true);
      setFinalShow(true);
    }
  };
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("fields") || "");

    const returnDate = sessionStorage.getItem("returndate");
    const releasedate = sessionStorage.getItem("releasedate");
    const nf = data.map((e: any, id: number) =>
      // eslint-disable-next-line no-prototype-builtins

      {
        if (id == 0) {
          {
            if (e.hasOwnProperty("device")) {
              return e;
            } else {
              Object.assign(e, {
                device: new Array(checkbox.length).fill(false),
              });
              return e;
            }
          }
        }
        if (id == 1) {
          setCheckedState(new Array(checkbox.length).fill(false));
          {
            if (e.hasOwnProperty("device")) {
              return e;
            } else {
              Object.assign(e, {
                device: new Array(checkbox.length).fill(false),
              });
              return e;
            }
          }
        }
        if (id == 2) {
          setCheckedState(new Array(checkbox.length).fill(false));
          {
            if (e.hasOwnProperty("device")) {
              return e;
            } else {
              Object.assign(e, {
                device: new Array(checkbox.length).fill(false),
              });
              return e;
            }
          }
        }
        if (id == 3) {
          setCheckedState(new Array(checkbox.length).fill(false));
          {
            if (e.hasOwnProperty("device")) {
              return e;
            } else {
              Object.assign(e, {
                device: new Array(checkbox.length).fill(false),
              });
              return e;
            }
          }
        }
        if (id == 4) {
          setCheckedState(new Array(checkbox.length).fill(false));
          {
            if (e.hasOwnProperty("device")) {
              return e;
            } else {
              Object.assign(e, {
                device: new Array(checkbox.length).fill(false),
              });
              return e;
            }
          }
        }
        if (id == 5) {
          setCheckedState(new Array(checkbox.length).fill(false));
          {
            if (e.hasOwnProperty("device")) {
              return e;
            } else {
              Object.assign(e, {
                device: new Array(checkbox.length).fill(false),
              });
              return e;
            }
          }
        }
        if (id == 6) {
          setCheckedState(new Array(checkbox.length).fill(false));
          {
            if (e.hasOwnProperty("device")) {
              return e;
            } else {
              Object.assign(e, {
                device: new Array(checkbox.length).fill(false),
              });
              return e;
            }
          }
        }
        if (id == 7) {
          setCheckedState(new Array(checkbox.length).fill(false));
          {
            if (e.hasOwnProperty("device")) {
              return e;
            } else {
              Object.assign(e, {
                device: new Array(checkbox.length).fill(false),
              });
              return e;
            }
          }
        }
      }
    );

    setFields(nf);

    setDates({
      releaseDate: releasedate,
      returnDate: returnDate,
    });
  }, []);
  return (
    <>
      <Modal.Body>
        <div className="electroniclogo">
          <img src={electronic} alt="" />
          <div className="topsection">
            <br />
            <h5>{t("electDevice")}</h5>
            <p>{t("electText")}</p>
          </div>
          <div className="date">
            {moment(dates.releaseDate).format("DD/MM/YYYY")} -{" "}
            {moment(dates.returnDate).format("DD/MM/YYYY")}
          </div>
          <div className="insured">
            <Row>
              {fields.map((e: any, i: number) => (
                <Col
                  className="insured"
                  md={12}
                  key={e.id}
                  onChange={(event: any) => {
                    handleChangeInput(i, event);
                  }}
                >
                  <h6>
                    {t("insured")} {i + 1}
                  </h6>
                  <p>{e.date}</p>

                  <div className="imgBordr">
                    <img src={user} alt="" />
                  </div>
                  <p>
                    {t("forEntire")}
                    <br />
                    {t("period")}
                  </p>
                  <p>{t("whichDevice")}</p>
                  <p>{t("selectTwo")}</p>

                  {checkbox.map(({ name, label }, index) => {
                    return (
                      <span className="spNew" key={index}>
                        <>
                          <label htmlFor={`custom-checkbox-${index}`}>
                            {label}
                          </label>
                          <input
                            type="checkbox"
                            id={`custom-checkbox-${index}`}
                            name={`device${index}${i}`}
                            value={name}
                            checked={e.device[index]}
                            disabled={e.device[index] ? false : e.electDone}
                            onChange={(event) =>
                              handleOnChange(index, event, i)
                            }
                          />
                        </>
                      </span>
                    );
                  })}
                </Col>
              ))}
            </Row>
          </div>
          <a href="#" className="underStnd" onClick={closeModal}>
            {t("understoodBut")}
          </a>
          <br />
          <Button className="addCovr" onClick={closeElectronicsModal}>
            {t("addCover")}&nbsp;<span className="plusIcon">+</span>
          </Button>
          <Modal
            show={finalshow}
            onHide={finalHandleClose}
            className="stepDmodal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <img src={shield} alt="" />
              <h6>{t("coverAdded")}</h6>
              <p>{t("policyUpdated")}</p>
              <p>$ {electronicData}</p>
            </Modal.Body>
          </Modal>
        </div>
      </Modal.Body>
    </>
  );
}

export default Electronic;
