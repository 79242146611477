/* eslint-disable no-prototype-builtins */
import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Health from "../StepFive/Health";
import Electronic from "../StepFive/Electronic";
import Sports from "../StepFive/Sports";
import Winter from "../StepFive/Winter";
import Vehicle from "../StepFive/Vehicle";
import First from "../StepFive/First";
import Second from "../StepFive/Second";
import Honor from "../StepFive/Honor";
import Pregnancy from "../StepFive/Pregnancy";
import shield from "../../assets/images/shield.png";
import pr1 from "../../assets/images/pr1.png";
import pr2 from "../../assets/images/pr2.png";
import pr3 from "../../assets/images/pr3.png";
import pr4 from "../../assets/images/pr4.png";
import pr5 from "../../assets/images/pr5.png";
import pr6 from "../../assets/images/pr6.png";
import pr7 from "../../assets/images/pr7.png";
import pr8 from "../../assets/images/pr8.png";
import info from "../../assets/images/info.png";
import { useTranslation } from "react-i18next";
import { CheckLg, TrashFill } from "react-bootstrap-icons";
import axios from "axios";
import FormData from "form-data";
import getTotalPrice from "../Utils/TotalPriceService";

function StepFive({ nextStep, prevStep }: any) {
  const [show, setShow] = useState(false);
  const [finalshow, setFinalShow] = useState(false);
  const [modalContent, setModalContent] = useState(0);
  //const [finalContent, setfinalContent] = useState(0);
  const [fields, setFields] = useState([]);
  const [healthDone, setHealthDone] = useState(false);
  const [vehicleDone, setVehicleDone] = useState(false);
  const [sportsDone, setSportsDone] = useState(false);
  const [winterDone, setWinterDone] = useState(false);
  const [electronicDone, setElectronicDone] = useState(false);
  const [firstDone, setFirstDone] = useState(false);
  const [secondDone, setSecondDone] = useState(false);
  const [honorDone, setHonorDone] = useState(false);
  const [pregnancyDone, setPregnancyDone] = useState(false);
  const [femaleSelected, setFemlaeSelected] = useState(false);
  const [priceData, setPriceData] = useState("");
  const [policyPrice, setPolicyPrice] = useState("");
  const openFinelHandle = () => {
    setFinalShow(true);
  };
  const { t, i18n } = useTranslation();
  const handleClose = () => {
    setShow(false);
    setPriceData(`${getTotalPrice()}`);
  };
  const finalHandleClose = () => {
    setFinalShow(false);
    nextStep();
  };
  const handleShow = (e: number) => {
    setModalContent(e);
    setShow(true);
  };

  const removeField = (e: string) => {
    const fields = JSON.parse(sessionStorage.getItem("fields"));
    // fields.map((f) => {
    //   Object.keys(f).filter((k, i) => {
    //     if (k.indexOf("health") == 0) {
    //       console.log(fields);
    //       console.log(i);
    //     }
    //   });
    // });
    console.log(e);
    if (e == "health") {
      const removedFields: { e: string }[] = [];
      fields.forEach((v: any) => {
        delete v.health;
        delete v.health_price;
        removedFields.push(v);
        setHealthDone(false);
      });
      setFields(removedFields);
      sessionStorage.setItem("fields", JSON.stringify(fields));
      sessionStorage.removeItem("health");
      sessionStorage.removeItem("healthvalue");
    }

    if (e == "pregnancy") {
      const removedFields: { e: string }[] = [];
      fields.forEach((v: any) => {
        delete v.pregnancy;
        delete v.pregnancy_price;
        removedFields.push(v);
        setPregnancyDone(false);
      });
      setFields(removedFields);
      sessionStorage.setItem("fields", JSON.stringify(fields));
      sessionStorage.removeItem("pregnancy");
      sessionStorage.removeItem("pregnancyvalue");
    }

    if (e == "electronic") {
      const removedFields: { e: string }[] = [];
      fields.forEach((v: any) => {
        delete v.device;
        delete v.electDone;
        delete v.electDone_price;
        removedFields.push(v);
        setElectronicDone(false);
      });
      setFields(removedFields);
      sessionStorage.setItem("fields", JSON.stringify(fields));
      sessionStorage.removeItem("electronic");
      sessionStorage.removeItem("electronicvalue");
    }

    if (e == "sports") {
      const removedFields: { e: string }[] = [];
      fields.forEach((v: any) => {
        delete v.sports;
        delete v.sports_price;
        removedFields.push(v);
        setSportsDone(false);
      });
      setFields(removedFields);
      sessionStorage.setItem("fields", JSON.stringify(fields));
      sessionStorage.removeItem("sports");
      sessionStorage.removeItem("sportsvalue");
    }

    if (e == "winter") {
      const removedFields: { e: string }[] = [];
      fields.forEach((v: any) => {
        delete v.winter;
        delete v.winter_price;
        removedFields.push(v);
        setWinterDone(false);
      });
      setFields(removedFields);
      sessionStorage.setItem("fields", JSON.stringify(fields));
      sessionStorage.removeItem("winter");
      sessionStorage.removeItem("wintervalue");
    }

    if (e == "vehicle") {
      const removedFields: { e: string }[] = [];
      fields.forEach((v: any) => {
        delete v.vehicle;
        delete v.vehicle_price;
        removedFields.push(v);
        setVehicleDone(false);
      });
      setFields(removedFields);
      sessionStorage.setItem("fields", JSON.stringify(fields));
      sessionStorage.removeItem("vehicle");
      sessionStorage.removeItem("vehiclevalue");
    }

    if (e == "first") {
      const removedFields: { e: string }[] = [];
      fields.forEach((v: any) => {
        delete v.first;
        delete v.first_price;
        removedFields.push(v);
        setFirstDone(false);
      });
      setFields(removedFields);
      sessionStorage.setItem("fields", JSON.stringify(fields));
      sessionStorage.removeItem("first_class");
      sessionStorage.removeItem("firstclassvalue");
    }

    if (e == "second") {
      const removedFields: { e: string }[] = [];
      fields.forEach((v: any) => {
        delete v.second;
        delete v.second_price;
        removedFields.push(v);
        setSecondDone(false);
      });
      setFields(removedFields);
      sessionStorage.setItem("fields", JSON.stringify(fields));
      sessionStorage.removeItem("second_class");
      sessionStorage.removeItem("secondclassvalue");
    }
    if (e == "honor") {
      const removedFields: { e: string }[] = [];
      fields.forEach((v: any) => {
        delete v.honor;
        delete v.honor_price;
        removedFields.push(v);
        setHonorDone(false);
      });
      setFields(removedFields);
      sessionStorage.setItem("fields", JSON.stringify(fields));
      sessionStorage.removeItem("honor");
      sessionStorage.removeItem("honorvalue");
    }

    setPriceData(`${getTotalPrice()}`);

    //console.log(removedFields);

    // const filtered = fields.filter(function (el) {
    //   console.log(el == e);
    // });
    //console.log(filtered);
  };
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("fields") || "");
    setFields(data);

    const healthArr: any[] = [];
    const firstArr: any[] = [];
    const secondArr: any[] = [];
    const honorArr: any[] = [];
    const deviceDoneArr: any[] = [];
    const vehicleDoneArr: any[] = [];
    const sportsDoneArr: any[] = [];
    const winterDoneArr: any[] = [];
    const pregnancyDoneArr: any[] = [];

    data.map((e: any) => {
      if (e.insured == "female") {
        setFemlaeSelected(true);
      }
      // eslint-disable-next-line no-prototype-builtins
      e.hasOwnProperty("health")
        ? healthArr.push(e.health)
        : setHealthDone(false);
      e.hasOwnProperty("pregnancy")
        ? pregnancyDoneArr.push(e.pregnancy)
        : setPregnancyDone(false);
      // eslint-disable-next-line no-prototype-builtins
      e.hasOwnProperty("first") ? firstArr.push(e.first) : setFirstDone(false);
      // eslint-disable-next-line no-prototype-builtins
      e.hasOwnProperty("second")
        ? secondArr.push(e.second)
        : setSecondDone(false);
      e.hasOwnProperty("honor") ? honorArr.push(e.honor) : setHonorDone(false);
      e.hasOwnProperty("electDone")
        ? deviceDoneArr.push(e.electDone)
        : setElectronicDone(false);
      e.hasOwnProperty("vehicle")
        ? e.vehicle.whole
          ? vehicleDoneArr.push(e.vehicle.whole)
          : e.vehicle.some.checked
          ? vehicleDoneArr.push(e.vehicle.some.checked)
          : setVehicleDone(false)
        : setVehicleDone(false);
      e.hasOwnProperty("sports")
        ? e.sports.whole
          ? sportsDoneArr.push(e.sports.whole)
          : e.sports.some.checked
          ? sportsDoneArr.push(e.sports.some.checked)
          : setSportsDone(false)
        : setSportsDone(false);
      e.hasOwnProperty("winter")
        ? e.winter.whole
          ? winterDoneArr.push(e.winter.whole)
          : e.winter.some.checked
          ? sportsDoneArr.push(e.winter.some.checked)
          : setWinterDone(false)
        : setWinterDone(false);
    });

    if (healthArr.includes(true)) {
      setHealthDone(true);
    }
    if (pregnancyDoneArr.includes(true)) {
      setPregnancyDone(true);
    }
    if (firstArr.includes(true)) {
      setFirstDone(true);
    }
    if (secondArr.includes(true)) {
      setSecondDone(true);
    }
    if (honorArr.includes(true)) {
      setHonorDone(true);
    }
    if (deviceDoneArr.includes(true)) {
      setElectronicDone(true);
    }
    if (vehicleDoneArr.includes(true)) {
      setVehicleDone(true);
    }
    if (sportsDoneArr.includes(true)) {
      setSportsDone(true);
    }
    if (winterDoneArr.includes(true)) {
      setWinterDone(true);
    }

    setPriceData(`${getTotalPrice()}`);
  }, []);

  //console.log(sessionStorage.getItem("travelwithcoro"));
  return (
    <div id="formbox">
      <div className="stepfreturn">
        <a href="#" onClick={prevStep} className="back">
          {t("return")}
        </a>
        <div className="topprice stepPrice">
          <p>
            ${priceData}
            <br />
            For the
          </p>
        </div>
      </div>
      <div className="topsection proposl">
        <h6>{t("yourPropsal")}</h6>
        <p>
          {t("beforContinue")}
          <br />
          {t("sugestIns")}
        </p>
      </div>
      <div className="contentbox stfiveboxborder">
        {healthDone ? (
          <TrashFill
            style={{
              cursor: "pointer",
              float: "left",
              marginLeft: "-30px",
              marginTop: "20px",
            }}
            onClick={() => {
              removeField("health");
            }}
          >
            X
          </TrashFill>
        ) : (
          ""
        )}
        <div
          className="healthmodal"
          onClick={() => {
            handleShow(1);
          }}
        >
          <div className="stf">
            <span className="stfPLus">
              {healthDone ? <CheckLg color="green" /> : "+"}
            </span>
            <p>
              <img src={info} alt="" />
              {t("existHealth")}
              <img src={pr1} alt="" />
            </p>
          </div>
        </div>
        {electronicDone ? (
          <TrashFill
            style={{
              cursor: "pointer",
              float: "left",
              marginLeft: "-30px",
              marginTop: "20px",
            }}
            onClick={() => {
              removeField("electronic");
            }}
          >
            X
          </TrashFill>
        ) : (
          ""
        )}
        <div
          className="electronicmodal"
          onClick={() => {
            handleShow(2);
          }}
        >
          <div className="stf">
            <span className="stfPLus">
              {electronicDone ? <CheckLg color="green" /> : "+"}
            </span>
            <p>
              <img src={info} alt="" />
              {t("electDevice")}
              <img src={pr2} alt="" />
            </p>
          </div>
        </div>
        {femaleSelected ? (
          <>
            {pregnancyDone ? (
              <TrashFill
                style={{
                  cursor: "pointer",
                  float: "left",
                  marginLeft: "-30px",
                  marginTop: "20px",
                }}
                onClick={() => {
                  removeField("pregnancy");
                }}
              >
                X
              </TrashFill>
            ) : (
              ""
            )}

            <div
              className="pregnancymodal"
              onClick={() => {
                handleShow(9);
              }}
            >
              <div className="stf">
                <span className="stfPLus">
                  {pregnancyDone ? <CheckLg color="green" /> : "+"}
                </span>
                <p>
                  <img src={info} alt="" />
                  {t("pregnancyUpto")}
                  <img src={pr2} alt="" />
                </p>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {sportsDone ? (
          <TrashFill
            style={{
              cursor: "pointer",
              float: "left",
              marginLeft: "-30px",
              marginTop: "20px",
            }}
            onClick={() => {
              removeField("sports");
            }}
          >
            X
          </TrashFill>
        ) : (
          ""
        )}
        <div
          className="sportsmodal"
          onClick={() => {
            handleShow(3);
          }}
        >
          <div className="stf">
            <span className="stfPLus">
              {sportsDone ? <CheckLg color="green" /> : "+"}
            </span>
            <p>
              <img src={info} alt="" />
              {t("extSport")}
              <img src={pr3} alt="" />
            </p>
          </div>
        </div>
        {winterDone ? (
          <TrashFill
            style={{
              cursor: "pointer",
              float: "left",
              marginLeft: "-30px",
              marginTop: "20px",
            }}
            onClick={() => {
              removeField("winter");
            }}
          >
            X
          </TrashFill>
        ) : (
          ""
        )}
        <div
          className="wintermodal"
          onClick={() => {
            handleShow(4);
          }}
        >
          <div className="stf">
            <span className="stfPLus">
              {" "}
              {winterDone ? <CheckLg color="green" /> : "+"}
            </span>
            <p>
              <img src={info} alt="" />
              {t("wintSports")}
              <img src={pr4} alt="" />
            </p>
          </div>
        </div>
        {vehicleDone ? (
          <TrashFill
            style={{
              cursor: "pointer",
              float: "left",
              marginLeft: "-30px",
              marginTop: "20px",
            }}
            onClick={() => {
              removeField("vehicle");
            }}
          >
            X
          </TrashFill>
        ) : (
          ""
        )}
        <div
          className="vehiclemodal"
          onClick={() => {
            handleShow(5);
          }}
        >
          <div className="stf">
            <span className="stfPLus">
              {vehicleDone ? <CheckLg color="green" /> : "+"}
            </span>
            <p>
              <img src={info} alt="" />
              {t("cancelVehicle")}
              <img src={pr5} alt="" />
            </p>
          </div>
        </div>
        {firstDone ? (
          <TrashFill
            style={{
              cursor: "pointer",
              float: "left",
              marginLeft: "-30px",
              marginTop: "20px",
            }}
            onClick={() => {
              removeField("first");
            }}
          >
            X
          </TrashFill>
        ) : (
          ""
        )}
        <div
          className="competitivemodal"
          onClick={() => {
            handleShow(6);
          }}
        >
          <div className="stf">
            <span className="stfPLus">
              {firstDone ? <CheckLg color="green" /> : "+"}
            </span>
            <p>
              <img src={info} alt="" />
              {t("firstClass")}
              <img src={pr6} alt="" />
            </p>
          </div>
        </div>
        {secondDone ? (
          <TrashFill
            style={{
              cursor: "pointer",
              float: "left",
              marginLeft: "-30px",
              marginTop: "20px",
            }}
            onClick={() => {
              removeField("second");
            }}
          >
            X
          </TrashFill>
        ) : (
          ""
        )}
        <div
          className="secondmodal"
          onClick={() => {
            handleShow(7);
          }}
        >
          <div className="stf">
            <span className="stfPLus">
              {secondDone ? <CheckLg color="green" /> : "+"}
            </span>
            <p>
              <img src={info} alt="" />
              {t("secondClass")}
              <img src={pr7} alt="" />
            </p>
          </div>
        </div>
        {honorDone ? (
          <TrashFill
            style={{
              cursor: "pointer",
              float: "left",
              marginLeft: "-30px",
              marginTop: "20px",
            }}
            onClick={() => {
              removeField("honor");
            }}
          >
            X
          </TrashFill>
        ) : (
          ""
        )}
        <div
          className="anothermodal"
          onClick={() => {
            handleShow(8);
          }}
        >
          <div className="stf">
            <span className="stfPLus">
              {honorDone ? <CheckLg color="green" /> : "+"}
            </span>
            <p>
              <img src={info} alt="" />
              {t("anoHonor")}
              <img src={pr8} alt="" />
            </p>
          </div>
        </div>
      </div>
      <div className="next-button-wr">
        <Button onClick={openFinelHandle}>{t("continued")}</Button>
      </div>
      <Modal show={finalshow} onHide={finalHandleClose} className="stepDmodal">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={shield} alt="" />
          <h6>{t("coverAdded")}</h6>
          <p>{t("policyUpdated")}</p>
          <p>$ {priceData + policyPrice}</p>
        </Modal.Body>
      </Modal>
      {modalContent === 1 ? (
        <Modal show={show} onHide={handleClose} className="stepDmodal">
          <Modal.Header closeButton></Modal.Header>
          <Health closeModal={handleClose} setHealthDone={setHealthDone} />
        </Modal>
      ) : modalContent === 2 ? (
        <Modal show={show} onHide={handleClose} className="stepDmodal">
          <Modal.Header closeButton></Modal.Header>
          <Electronic
            closeModal={handleClose}
            setElectronicDone={setElectronicDone}
          />
        </Modal>
      ) : modalContent === 3 ? (
        <Modal show={show} onHide={handleClose} className="stepDmodal">
          <Modal.Header closeButton></Modal.Header>
          <Sports closeModal={handleClose} setSportsDone={setSportsDone} />
        </Modal>
      ) : modalContent === 4 ? (
        <Modal show={show} onHide={handleClose} className="stepDmodal">
          <Modal.Header closeButton></Modal.Header>
          <Winter closeModal={handleClose} setWinterDone={setWinterDone} />
        </Modal>
      ) : modalContent === 5 ? (
        <Modal show={show} onHide={handleClose} className="stepDmodal">
          <Modal.Header closeButton></Modal.Header>
          <Vehicle closeModal={handleClose} setVehicleDone={setVehicleDone} />
        </Modal>
      ) : modalContent === 6 ? (
        <Modal show={show} onHide={handleClose} className="stepDmodal">
          <Modal.Header closeButton></Modal.Header>
          <First closeModal={handleClose} setFirstDone={setFirstDone} />
        </Modal>
      ) : modalContent === 7 ? (
        <Modal show={show} onHide={handleClose} className="stepDmodal">
          <Modal.Header closeButton></Modal.Header>
          <Second closeModal={handleClose} setSecondDone={setSecondDone} />
        </Modal>
      ) : modalContent === 8 ? (
        <Modal show={show} onHide={handleClose} className="stepDmodal">
          <Modal.Header closeButton></Modal.Header>
          <Honor closeModal={handleClose} setHonorDone={setHonorDone} />
        </Modal>
      ) : modalContent === 9 ? (
        <Modal show={show} onHide={handleClose} className="stepDmodal">
          <Modal.Header closeButton></Modal.Header>
          <Pregnancy
            closeModal={handleClose}
            setPregnancyDone={setPregnancyDone}
          />
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
}

export default StepFive;
