import axios from "axios";

const getPrice = (formData, key) => {
  const selectedCountry = sessionStorage.getItem("selectedCountry");
  return new Promise((resolve, reject) => {
    return axios({
      method: "POST",
      url: "https://travelins.ajjawi.com/backoffice/api/pricing/",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then(function (response) {
        let totalPrice = 0;
        const pessangerData = JSON.parse(sessionStorage.getItem("fields"));
        pessangerData.forEach((data) => {
          if (key == "basic") {
            const { usa_price, others_price } = getPriceBasedOnAge(
              response,
              data
            );
            data[`${key}_price`] = others_price;
            data[`${key}_usa_price`] = usa_price;
            totalPrice =
              totalPrice +
              (selectedCountry == '"usa"' ? usa_price : others_price);
          } else if (
            key == "corona" &&
            sessionStorage.getItem("travelwithcoro") == "including"
          ) {
            const { usa_price, others_price } = getPriceBasedOnAge(
              response,
              data
            );
            data[`${key}_price`] = others_price;
            data[`${key}_usa_price`] = usa_price;
            totalPrice =
              totalPrice +
              (selectedCountry == '"usa"' ? usa_price : others_price);
          } else if (data[key]) {
            const { usa_price, others_price } = getPriceBasedOnAge(
              response,
              data
            );
            data[`${key}_price`] = others_price;
            data[`${key}_usa_price`] = usa_price;
            totalPrice =
              totalPrice +
              (selectedCountry == '"usa"' ? usa_price : others_price);
          } else {
            delete data[`${key}_price`];
            delete data[`${key}_usa_price`];
          }
        });
        sessionStorage.setItem("fields", JSON.stringify(pessangerData));
        resolve(totalPrice);
      })
      .catch(function (error) {
        reject(error);
        console.log(error);
      });
  });
};

const getPriceBasedOnAge = (response, data) => {
  const prices = response.data;
  let selectedPrice = {
    usa_price: 0,
    others_price: 0,
  };
  //const selectedCountry = sessionStorage.getItem("selectedCountry");
  prices.forEach((price) => {
    if (data.age >= price.min && data.age <= price.max) {
      selectedPrice.usa_price = parseFloat(price.usa_price);
      selectedPrice.others_price = parseFloat(price.others_price);
    }
  });
  return selectedPrice;
};

export default getPrice;
