import { Button, Col, Modal, Row } from "react-bootstrap";
import car from "../../assets/images/car.png";
import user from "../../assets/images/user.png";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import shield from "../../assets/images/shield.png";
import pricing from "../../assets/json/pricing.json";
import axios from "axios";
import FormData from "form-data";
import getPrice from "../Utils/PriceService";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function Vehicle({ closeModal, setVehicleDone }: any) {
  const { t, i18n } = useTranslation();
  const [dates, setDates] = useState({
    releaseDate: new Date(),
    returnDate: new Date(),
  });
  const [finalshow, setFinalShow] = useState(false);
  const [fields, setFields] = useState([]);
  const [vehicleData, setvehicleData] = useState("");
  const [someDate, setSomeDate] = useState({
    releaseDate: new Date(),
    returnDate: new Date(),
  });
  const finalHandleClose = () => {
    closeModal();
    setFinalShow(false);
  };
  const closeVehicleModal = () => {
    sessionStorage.setItem("fields", JSON.stringify(fields));
    const vehicleArr: any[] = [];
    fields.map((e: any) =>
      // eslint-disable-next-line no-prototype-builtins
      e.hasOwnProperty("vehicle")
        ? e.vehicle.some.checked == true
          ? vehicleArr.push(e.vehicle.some.checked)
          : e.vehicle.whole == true
          ? vehicleArr.push(e.vehicle.whole)
          : setVehicleDone(false)
        : setVehicleDone(false)
    );

    const vehicleFormData = new FormData();
    vehicleFormData.append("item", "vehicle");
    getPrice(vehicleFormData, `vehicle`)
      .then((data: any) => {
        setvehicleData(`${data}`);
      })
      .catch((error) => {
        setvehicleData("");
      });

    if (vehicleArr.includes(true)) {
      setVehicleDone(true);
      setFinalShow(true);
    }
  };
  const handleChangeInput = (id: any, event: any) => {
    //alert(event.target.value);
    const newInputFields = fields.map((i: any, idx) => {
      // console.log(i);
      if (id === idx) {
        if (event.target.value == "some") {
          i["vehicle"] = {
            whole: false,
            some: {
              checked: true,
              dates: {
                release: someDate.releaseDate,
                return: someDate.returnDate,
              },
            },
          };
        } else {
          i["vehicle"] = {
            whole: true,
            some: {
              checked: false,
              dates: {
                release: someDate.releaseDate,
                return: someDate.returnDate,
              },
            },
          };
        }
        const years = i.age;

        const {
          min: fmin,
          max: fmax,
          price: fprice,
        } = pricing[2].additional.first;
        const {
          min: smin,
          max: smax,
          price: sprice,
        } = pricing[2].additional.second;
        if (years >= fmin && years <= fmax) {
          i["price"] = fprice + i.price;
        }

        if (years >= smin && years <= smax) {
          i["price"] = sprice + i.price;
        }
      }
      return i;
    });
    setFields(newInputFields);
    console.log(fields);
    console.log(fields[0].vehicle.some.checked);
    if (fields[0].vehicle.some.checked == true) {
      sessionStorage.setItem("vehicle", "true");
    }
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].vehicle.some.checked) {
        fields[i].vehicleValue = sessionStorage.getItem("vehiclevalue");
      } else {
        fields[i].vehicleValue = 0;
      }
    }
    sessionStorage.setItem("fields", JSON.stringify(fields));
  };
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("fields") || "");

    const nf = data.map((e: any) =>
      // eslint-disable-next-line no-prototype-builtins
      e.hasOwnProperty("vehicle")
        ? e
        : Object.assign(e, {
            vehicle: {
              whole: false,
              some: {
                checked: false,
                dates: {
                  release: someDate.releaseDate,
                  return: someDate.returnDate,
                },
              },
            },
          })
    );
    setFields(nf);
    // console.log(data);
    const returnDate = sessionStorage.getItem("returndate");
    const releasedate = sessionStorage.getItem("releasedate");
    setDates({
      releaseDate: moment(releasedate).toDate(),
      returnDate: moment(returnDate).toDate(),
    });
    setSomeDate({
      releaseDate: moment(releasedate).toDate(),
      returnDate: moment(returnDate).toDate(),
    });
  }, []);
  return (
    <>
      <Modal.Body>
        <div className="sportslogo">
          <img src={car} alt="" />
          <div className="topsection">
            <h6>{t("cancelVehicle")}</h6>
            <p>{t("vehicleText")}</p>
          </div>
          <div className="date">
            {moment(dates.releaseDate).format("DD/MM/YYYY")} -{" "}
            {moment(dates.returnDate).format("DD/MM/YYYY")}
          </div>
          <div className="insured">
            <Row>
              {fields.map((e: any, i: number) => (
                <Col className="insured" md={12} key={i}>
                  <h6>
                    {t("insured")} {i + 1}
                  </h6>
                  <p>{e.date}</p>

                  <div className="imgBordr">
                    <img src={user} alt="" />
                  </div>
                  <p>
                    {t("forEntire")}
                    <br />
                    {t("period")}
                  </p>
                  <p>{t("onDate")}</p>
                  <div
                    className="days"
                    onChange={(event: any) => {
                      handleChangeInput(i, event);
                    }}
                  >
                    <label htmlFor="">{t("justSomeDay")}</label>
                    <input
                      type="radio"
                      name={`days${i + 1}`}
                      id=""
                      value="some"
                      checked={e.vehicle.some.checked}
                    />
                    {e.vehicle.some.checked ? (
                      <div
                        className="return-release-input"
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <div className="return-input">
                          <DatePicker
                            selected={moment(someDate.returnDate).toDate()}
                            minDate={moment(dates.releaseDate).toDate()}
                            maxDate={moment(dates.returnDate).toDate()}
                            onChange={(e: Date) => {
                              setSomeDate({
                                returnDate: e,
                                releaseDate: someDate.releaseDate,
                              });
                            }}
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                        <div className="release-input">
                          <DatePicker
                            selected={moment(someDate.releaseDate).toDate()}
                            minDate={moment(dates.releaseDate).toDate()}
                            maxDate={moment(dates.returnDate).toDate()}
                            onChange={(e: Date) => {
                              setSomeDate({
                                returnDate: someDate.returnDate,
                                releaseDate: e,
                              });
                            }}
                            dateFormat="dd/MM/yyyy"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <label htmlFor="">{t("wholeTrip")}</label>
                    <input
                      type="radio"
                      name={`days${i + 1}`}
                      id=""
                      value="whole"
                      checked={e.vehicle.whole}
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <a href="#" onClick={closeModal}>
            {t("understoodBut")}
          </a>
          <br />
          <Button onClick={closeVehicleModal}>{t("addCover")}</Button>
        </div>
        <Modal
          show={finalshow}
          onHide={finalHandleClose}
          className="stepDmodal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <img src={shield} alt="" />
            <h6>{t("coverAdded")}</h6>
            <p>{t("policyUpdated")}</p>
            <p>$ {vehicleData}</p>
          </Modal.Body>
        </Modal>
      </Modal.Body>
    </>
  );
}

export default Vehicle;
