import axios from "axios";

const getTotalPrice = () => {
  let total = 0;
  const selectedCountry = sessionStorage.getItem("selectedCountry");
  const fields = JSON.parse(sessionStorage.getItem("fields"));
  fields.forEach((data) => {
    if (data.health_price) {
      total +=
        selectedCountry == '"usa"' ? data.health_usa_price : data.health_price;
    }
    if (data.honor_price) {
      total +=
        selectedCountry == '"usa"' ? data.honor_usa_price : data.honor_price;
    }

    if (data.vehicle_price) {
      total +=
        selectedCountry == '"usa"'
          ? data.vehicle_usa_price
          : data.vehicle_price;
    }

    if (data.sports_price) {
      total +=
        selectedCountry == '"usa"' ? data.sports_usa_price : data.sports_price;
    }

    if (data.winter_price) {
      total +=
        selectedCountry == '"usa"' ? data.winter_usa_price : data.winter_price;
    }

    if (data.first_price) {
      total +=
        selectedCountry == '"usa"' ? data.first_usa_price : data.first_price;
    }

    if (data.second_price) {
      total +=
        selectedCountry == '"usa"' ? data.second_usa_price : data.second_price;
    }

    if (data.electDone_price) {
      total +=
        selectedCountry == '"usa"'
          ? data.electDone_usa_price
          : data.electDone_price;
    }

    if (data.basic_price) {
      total +=
        selectedCountry == '"usa"' ? data.basic_usa_price : data.basic_price;
    }

    if (data.corona_price) {
      total +=
        selectedCountry == '"usa"' ? data.corona_usa_price : data.corona_price;
    }

    if (data.pregnancy_price) {
      total +=
        selectedCountry == '"usa"'
          ? data.pregnancy_usa_price
          : data.pregnancy_price;
    }
  });
  return total;
};
export default getTotalPrice;
