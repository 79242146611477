import { useEffect, useState } from "react";
import { Button, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import getTotalPrice from "../Utils/TotalPriceService";
//import { handleInputChange } from "react-select/src/utils";

function StepSix({ nextStep, prevStep }: any) {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [finalshow, setFinalShow] = useState(false);
  const [modalContent, setModalContent] = useState(0);
  const [healthCondition, setHealthCondition] = useState(false);
  const [healthConditionMulti, setHealthConditionMulti] = useState(false);
  const [healthSubCondition, setHealthSubCondition] = useState(false);
  //const [finalContent, setfinalContent] = useState(0);
  const [fields, setFields] = useState([]);
  const [priceData, setPriceData] = useState("");
  const finalHandleClose = () => {
    setFinalShow(false);
    nextStep();
  };
  const handleShow = (e: number) => {
    setModalContent(e);
    setShow(true);
  };

  const handleInputChange = (e: any) => {
    setHealthCondition(!healthCondition);
    sessionStorage.setItem("healthCondition", JSON.stringify(!healthCondition));
  };
  const handleSubConditionChange = (e: any) => {
    // alert(!healthSubCondition);
    setHealthSubCondition(!healthSubCondition);
    sessionStorage.setItem(
      "healthSubCondition",
      JSON.stringify(!healthSubCondition)
    );
  };
  const handleInputChangeMulti = () => {
    setHealthConditionMulti(!healthConditionMulti);
    sessionStorage.setItem(
      "healthConditionMulti",
      JSON.stringify(!healthConditionMulti)
    );
  };

  const handleSubConditionChangeMulti = (id: any, e: any) => {
    const newInputFields = fields.map((i: any, idx: any) => {
      // console.log(i);
      if (id === idx) {
        if (e.target.value == "Yes") {
          i["healthCondition"] = {
            Yes: {
              checked: true,
              sub: {
                Yes: false,
                No: true,
              },
            },
            No: false,
          };
        } else {
          i["healthCondition"] = {
            Yes: {
              checked: false,
              sub: {
                Yes: false,
                No: true,
              },
            },
            No: true,
          };
        }
      }
      return i;
    });

    setFields(newInputFields);
    sessionStorage.setItem("fields", JSON.stringify(newInputFields));
  };
  const handleSubConditionChangeMultiSub = (id: any, e: any) => {
    const newInputFields = fields.map((i: any, idx: any) => {
      // console.log(i);
      if (id === idx) {
        if (e.target.value == "Yes") {
          i["healthCondition"] = {
            Yes: {
              checked: true,
              sub: {
                Yes: true,
                No: false,
              },
            },
            No: false,
          };
        } else {
          i["healthCondition"] = {
            Yes: {
              checked: true,
              sub: {
                Yes: false,
                No: true,
              },
            },
            No: false,
          };
        }
      }
      return i;
    });

    setFields(newInputFields);
    sessionStorage.setItem("fields", JSON.stringify(newInputFields));
  };

  useEffect(() => {
    if (sessionStorage.getItem("healthCondition") != null) {
      const hc = JSON.parse(sessionStorage.getItem("healthCondition") || "");
      setHealthCondition(hc);
    }
    if (sessionStorage.getItem("healthConditionMulti") != null) {
      const hcm = JSON.parse(
        sessionStorage.getItem("healthConditionMulti") || ""
      );
      setHealthConditionMulti(hcm);
    }
    if (sessionStorage.getItem("healthSubCondition") != null) {
      const hsc = JSON.parse(
        sessionStorage.getItem("healthSubCondition") || ""
      );
      setHealthSubCondition(hsc);
    }
    const data = JSON.parse(sessionStorage.getItem("fields") || "");

    const nf = data.map((e: any) =>
      // eslint-disable-next-line no-prototype-builtins
      e.hasOwnProperty("healthCondition")
        ? e
        : Object.assign(e, {
            healthCondition: {
              Yes: {
                checked: false,
                sub: {
                  Yes: false,
                  No: true,
                },
              },
              No: true,
            },
          })
    );
    setFields(nf);
    sessionStorage.setItem("fields", JSON.stringify(nf));
    setPriceData(`${getTotalPrice()}`);
  }, []);

  return (
    <div id="formbox">
      <a href="#" onClick={prevStep} className="back">
        {t("return")}
      </a>

      <div className="topsection">
        <div className="topprice">
          <p>${priceData}</p>
        </div>
        {fields.length >= 2 ? (
          <div className="contentbox">
            <h6>{t("healthDec")}</h6>
            <p>{t("healthImp")}</p>
            <p>{t("pleaseSure")}</p>
            <h6>{t("oneOrMore")}</h6>
            <p>{t("mediReg")}</p>
            <p>{t("changeHealth")}</p>
            <p>{t("oneOfFollowing")}</p>
            <div className="days" onChange={handleInputChangeMulti}>
              <label htmlFor="">Yes to one or more passengers</label>
              <input
                type="radio"
                name="healthCondition"
                id=""
                value="yes"
                checked={healthConditionMulti}
              />
              <label htmlFor="">Not for all passengers</label>
              <input
                type="radio"
                name="healthCondition"
                id=""
                value="No"
                checked={!healthConditionMulti}
              />
            </div>
            {fields.map((e: any, i: number) => (
              <Col className="insured" key={i}>
                {healthConditionMulti ? (
                  <div className="hc" style={{ border: "2px solid black" }}>
                    <h6>
                      {t("insured")} {i + 1}
                    </h6>
                    <label htmlFor="">{t("yes")}</label>
                    <input
                      type="radio"
                      name={`hc ${i + 1}`}
                      id=""
                      value="Yes"
                      checked={e.healthCondition.Yes.checked}
                      onChange={(e) => {
                        handleSubConditionChangeMulti(i, e);
                      }}
                    />
                    <label htmlFor="">{t("no")}</label>
                    <input
                      type="radio"
                      name={`hc ${i + 1}`}
                      id=""
                      value="No"
                      checked={e.healthCondition.No}
                      onChange={(e) => {
                        handleSubConditionChangeMulti(i, e);
                      }}
                    />
                    <p>{t("stepSix")}</p>
                    {e.healthCondition.Yes.checked ? (
                      <>
                        <label htmlFor="">{t("yes")}</label>
                        <input
                          type="radio"
                          name={`hci ${i + 2}`}
                          id=""
                          value="Yes"
                          checked={e.healthCondition.Yes.sub.Yes}
                          onChange={(e) => {
                            handleSubConditionChangeMultiSub(i, e);
                          }}
                        />
                        <label htmlFor="">{t("no")}</label>
                        <input
                          type="radio"
                          name={`hci ${i + 2}`}
                          id=""
                          value="No"
                          checked={e.healthCondition.Yes.sub.No}
                          onChange={(e) => {
                            handleSubConditionChangeMultiSub(i, e);
                          }}
                        />
                        <p>{t("stepsix_two")}</p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </Col>
            ))}
          </div>
        ) : (
          <div className="contentbox">
            <h6>{t("healthDec")}</h6>
            <p>{t("healthImp")}</p>
            <p>{t("pleaseSure")}</p>
            <h6>{t("oneOrMore")}</h6>
            <p>{t("mediReg")}</p>
            <p>{t("changeHealth")}</p>
            <p>{t("oneOfFollowing")}</p>
            <div className="days" onChange={handleInputChange}>
              <label htmlFor="">{t("yes")}</label>
              <input
                type="radio"
                name="days"
                id=""
                value="yes"
                checked={healthCondition}
              />
              <label htmlFor="">{t("no")}</label>
              <input
                type="radio"
                name="days"
                id=""
                value="No"
                checked={!healthCondition}
              />
            </div>
            {healthCondition ? (
              <div
                className="hc"
                style={{ border: "2px solid black" }}
                onClick={handleSubConditionChange}
              >
                <label htmlFor="">{t("yes")}</label>
                <input
                  type="radio"
                  name="hc"
                  id=""
                  checked={healthSubCondition}
                  value="yes"
                />
                <label htmlFor="">{t("no")}</label>
                <input
                  type="radio"
                  name="hc"
                  id=""
                  checked={!healthSubCondition}
                  value="No"
                />
                <p>{t("stepSix")}</p>
              </div>
            ) : (
              ""
            )}
          </div>
        )}

        <p>{t("basicPolicy")}</p>
        <p>{t("changeInMed")}</p>
      </div>
      <div className="next-button-wr">
        <Button onClick={nextStep}>{t("continued")}</Button>
      </div>
    </div>
  );
}

export default StepSix;
