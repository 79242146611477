/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import CountryList from "../../assets/json/countries.json";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";

function StepTwo({ nextStep, prevStep }: any) {
  const { t, i18n } = useTranslation();
  const [country, setCountry] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("");

  //const image: Country = CountryList;

  const handleChange = (selectedOption: any) => {
    setCountry(selectedOption);
    setSelectedCountry(selectedOption.value);
    sessionStorage.setItem("country", JSON.stringify(selectedOption));
    sessionStorage.setItem(
      "selectedCountry",
      JSON.stringify(selectedOption.value)
    );
  };

  const handleCountryName = (e: any) => {
    setCountry(e);
    setSelectedCountry(e.value);
    sessionStorage.setItem("country", JSON.stringify(e));
    sessionStorage.setItem("selectedCountry", JSON.stringify(e.value));
  };

  const newCountryList: { value: string; label: string }[] = [];

  CountryList.forEach((element) => {
    const { image, ...rest } = element;
    newCountryList.push(rest);
    // console.log(element);
  });

  useEffect(() => {
    if (sessionStorage.getItem("country") != null) {
      setCountry(JSON.parse(sessionStorage.getItem("country") || ""));
    }
    if (sessionStorage.getItem("selectedCountry") != null) {
      setSelectedCountry(
        JSON.parse(sessionStorage.getItem("selectedCountry") || "")
      );
    }
  }, []);

  const submitForm = (e: any) => {
    e.preventDefault();
    if (country == null) {
      toast.error("Select country", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      nextStep();
    }
  };
  return (
    <form id="formbox" onSubmit={submitForm}>
      <ToastContainer rtl={true} />
      <a href="#" className="back" onClick={prevStep}>
        {t("return")}
      </a>
      <div className="topsection">
        <h6>{t("whereWeGoing")}</h6>
        <p>{t("selectCountry")}</p>
      </div>
      <div className="contentbox">
        <Select
          placeholder={`${t("countryName")}`}
          options={newCountryList}
          value={country}
          onChange={handleChange}
          required
        />
        <p className="hints">{t("stepTwoHints")}</p>
        <div className="setpBmain">
          {CountryList.map((el, i) => {
            return (
              <>
                <div
                  className="stepBinn"
                  onClick={() => {
                    handleCountryName(el);
                  }}
                  key={i}
                >
                  {el.common ? (
                    <>
                      <input type="radio" name="city" id="" value={el.value} />
                      <div
                        className={`stepBimg ${
                          selectedCountry == el.value ? "stepBinnActive" : ""
                        }`}
                      >
                        <img src={el.image} alt="" />
                      </div>
                      <label htmlFor="passengerNumber">{el.label}</label>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </>
            );
          })}
        </div>
        <p>{t("enemyText")}</p>
      </div>
      <div className="next-button-wr">
        <input
          className="formsubmit"
          type="submit"
          value={`${t("continue")}`}
        />
      </div>
    </form>
  );
}

export default StepTwo;
