/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import "./App.scss";
import { Container, Row, Col } from "react-bootstrap";
import { useState } from "react";
import StepOne from "./Components/Forms/StepOne";
import StepTwo from "./Components/Forms/StepTwo";
import StepThree from "./Components/Forms/StepThree";
import StepFour from "./Components/Forms/StepFour";
import StepFive from "./Components/Forms/StepFive";
import StepSix from "./Components/Forms/StepSix";
import StepSeven from "./Components/Forms/StepSeven";
import StepEight from "./Components/Forms/StepEight";

import { useTranslation } from "react-i18next";

function App() {
  const [step, setstep] = useState(1);

  // const changeLanguageHandler = (e) => {
  //   const languageValue = e.target.value;
  //   i18n.changeLanguage(languageValue);
  // };
  //console.log(StepOne);

  const nextStep = () => {
    setstep(step + 1);
  };
  const prevStep = () => {
    setstep(step - 1);
  };

  switch (step) {
    case 1:
      return (
        <div className="App contentwrap">
          <div className="logo"></div>
          <Container>
            <Row>
              <Col md={6} className="custom-margin">
                <StepOne nextStep={nextStep} />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 2:
      return (
        <div className="App contentwrap">
          <div className="logo"></div>
          <Container>
            <Row>
              <Col md={6} className="custom-margin">
                <StepTwo nextStep={nextStep} prevStep={prevStep} />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 3:
      return (
        <div className="App contentwrap">
          <div className="logo"></div>
          <Container>
            <Row>
              <Col md={6} className="custom-margin">
                <StepThree nextStep={nextStep} prevStep={prevStep} />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 4:
      return (
        <div className="App contentwrap">
          <div className="logo"></div>
          <Container>
            <Row>
              <Col md={6} className="custom-margin">
                <StepFour nextStep={nextStep} prevStep={prevStep} />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 5:
      return (
        <div className="App contentwrap">
          <div className="logo"></div>
          <Container>
            <Row>
              <Col md={6} className="custom-margin">
                <StepFive nextStep={nextStep} prevStep={prevStep} />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 6:
      return (
        <div className="App contentwrap">
          <div className="logo"></div>
          <Container>
            <Row>
              <Col md={6} className="custom-margin">
                <StepSix nextStep={nextStep} prevStep={prevStep} />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 7:
      return (
        <div className="App contentwrap">
          <div className="logo"></div>
          <Container>
            <Row>
              <Col md={6} className="custom-margin">
                <StepSeven nextStep={nextStep} prevStep={prevStep} />
              </Col>
            </Row>
          </Container>
        </div>
      );
    case 8:
      return (
        <div className="App contentwrap">
          <div className="logo"></div>
          <Container>
            <Row>
              <Col md={6} className="custom-margin">
                <StepEight nextStep={nextStep} prevStep={prevStep} />
              </Col>
            </Row>
          </Container>
        </div>
      );
    default:
      return (
        <div className="App contentwrap">
          <div className="logo"></div>
          <Container>
            <Row>
              <Col md={6} className="custom-margin"></Col>
            </Row>
          </Container>
        </div>
      );
      break;
  }
}

export default App;
