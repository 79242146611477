/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PlusLg, TrashFill } from "react-bootstrap-icons";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { log } from "console";
import pricing from "../../assets/json/pricing.json";
const StepOne = ({ nextStep }: { nextStep: any }): JSX.Element => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [fnEmpty, setFnEmpty] = useState(false);
  const [lnEmpty, setLnEmpty] = useState(false);
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [isMobile, setIsMobile] = useState(true);
  const [isIDNumber, setisIDNumber] = useState(new Array(8).fill(true));
  const [isRequiredIN, setisRequiredIN] = useState(new Array(8).fill(true));

  // const [isRequiredIN, setIsRequiredIN] = useState(true);
  const [isRequiredDate, setIsRequiredDate] = useState(true);
  const [todayDate, setTodayDate] = useState(new Date());

  function parse_query_string(query: string) {
    const vars = query.split("?");
    const query_string: any = {};
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      const key = decodeURIComponent(pair[0]);
      const value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        const arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
    return query_string;
  }

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    mobile: false,
  });
  const [fields, setFields] = useState([
    {
      insured: "",
      idNumber: "",
      firstName: "",
      lastName: "",
      date: "",
      mobilePhone: "",
      price: "",
      age: "",
    },
  ]);
  useEffect(() => {
    if (sessionStorage.getItem("firstName") != null) {
      setFirstName(JSON.parse(sessionStorage.getItem("firstName") || ""));
    }
    if (sessionStorage.getItem("lastName") != null) {
      setLastName(JSON.parse(sessionStorage.getItem("lastName") || ""));
    }
    if (sessionStorage.getItem("mobile") != null) {
      setMobile(JSON.parse(sessionStorage.getItem("mobile") || ""));
    }
    if (sessionStorage.getItem("fields") != null) {
      setFields(JSON.parse(sessionStorage.getItem("fields") || ""));
    }
  }, []);
  const [passengerNumber, setPassengerNumber] = useState(1);
  const [showMore, setShowMore] = useState(false);

  if (sessionStorage.getItem("noOfPassenger") === null) {
    sessionStorage.setItem("noOfPassenger", "1");
  }

  if (sessionStorage.getItem("fields") === null) {
    sessionStorage.setItem(
      "fields",
      JSON.stringify([
        {
          insured: "",
          idNumber: "",
          firstName: "",
          lastName: "",
          date: "",
          mobilePhone: "",
          price: "",
          age: "",
        },
      ])
    );
  }

  function handleRemove(id: any) {
    console.log(fields);

    const localFields = JSON.parse(sessionStorage.getItem("fields") || "");

    const filteredAry = localFields.filter((_e: any, i: any) => i !== id);

    console.log(filteredAry);

    setFields(filteredAry);

    const curNoOfPass = JSON.parse(
      sessionStorage.getItem("noOfPassenger") || ""
    );

    sessionStorage.setItem("fields", JSON.stringify(filteredAry));

    const makePass = parseInt(curNoOfPass) - 1;

    sessionStorage.setItem("noOfPassenger", JSON.stringify(makePass));
  }

  const handlePassengerNumber = (e: any) => {
    sessionStorage.setItem("noOfPassenger", e.target.value);

    const localFields = JSON.parse(sessionStorage.getItem("fields") || "");
    const curNoOfPass = JSON.parse(
      sessionStorage.getItem("noOfPassenger") || ""
    );

    //sessionStorage.setItem("fields", localFields);
    const values = [...fields];
    //console.log(values.splice(0, e.target.value));

    console.log(localFields);

    let i: number;

    const manArr = [...localFields];

    if (curNoOfPass >= localFields.length) {
      for (i = 0; i < e.target.value - localFields.length; i++) {
        manArr.push({
          insured: "",
          idNumber: "",
          firstName: "",
          lastName: "",
          date: "",
          mobilePhone: "",
        });
      }
    } else {
      manArr.length = curNoOfPass;
    }
    console.log(manArr);

    sessionStorage.setItem("fields", JSON.stringify(manArr));

    //console.log(values);
    setFields(manArr);
    setPassengerNumber(e.target.value);
  };
  const handleShowMore = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setShowMore(!showMore);
  };

  function handleAdd() {
    const curNoOfPass = JSON.parse(
      sessionStorage.getItem("noOfPassenger") || ""
    );

    const makePass = parseInt(curNoOfPass) + 1;

    sessionStorage.setItem("noOfPassenger", JSON.stringify(makePass));
    const localFields = JSON.parse(sessionStorage.getItem("fields") || "");

    const values = [...fields];
    //console.log(values);
    values.push({
      insured: "",
      idNumber: "",
      firstName: "",
      lastName: "",
      date: "",
      mobilePhone: "",
      price: "",
      age: "",
    });
    setFields(values);
    console.log(localFields);

    sessionStorage.setItem("fields", JSON.stringify(values));
  }

  const handleInitialFormData = (id: any, event: any) => {
    if (id == "firstName") {
      setFirstName(event.target.value.split(" ").join(""));
      //setFnEmpty(false);
      setError({
        firstName: false,
        lastName: error.lastName,
        mobile: error.mobile,
      });
    }
    if (id == "lastName") {
      setLastName(event.target.value.split(" ").join(""));
      // setLnEmpty(false);
      setError({
        firstName: error.firstName,
        lastName: false,
        mobile: error.mobile,
      });
    }
    if (id == "mobile") {
      setError({
        firstName: error.firstName,
        lastName: error.lastName,
        mobile: false,
      });
      if (isNaN(event.target.value)) {
        setIsMobile(false);
        setMobile(null);
        sessionStorage.setItem(id, JSON.stringify(null));
      } else {
        setIsMobile(true);
        setMobile(event.target.value);
      }
    }
    if (
      error.firstName == false &&
      error.lastName == false &&
      error.mobile == false &&
      isMobile == true
    ) {
      sessionStorage.setItem(id, JSON.stringify(event.target.value));
    }
  };

  const handleChangeInput = (id: any, event: any) => {
    const localFields = JSON.parse(sessionStorage.getItem("fields") || "");

    const newInputFields = fields.map((i: any, idx) => {
      if (id === idx) {
        if (event.target.name == "idNumber") {
          setisRequiredIN(new Array(8).fill(true));
          if (event.target.value == "") {
            const updatisRequiredState = isRequiredIN.map((item, index) =>
              index === idx ? !item : item
            );
            //console.log(updatisRequiredState);

            setisRequiredIN((prevState) => [
              ...prevState,
              updatisRequiredState,
            ]);

            console.log(isRequiredIN);

            //setisRequiredIN(updatisRequiredState);
          }
          //setisIDNumber(false);
          if (isNaN(event.target.value)) {
            const updatedidNumberState = isIDNumber.map((item, index) =>
              index === idx ? !item : item
            );
            setisIDNumber(updatedidNumberState);
          } else {
            setisIDNumber(new Array(8).fill(true));
            i[event.target.name] = event.target.value;
          }
        } else if (event.target.name.startsWith("insur")) {
          i["insured"] = event.target.value;
        } else {
          const years = moment().diff(event.target.value, "years");
          const {
            min: fmin,
            max: fmax,
            price: fprice,
          } = pricing[0].ageGroup.first;
          const {
            min: smin,
            max: smax,
            price: sprice,
          } = pricing[0].ageGroup.second;
          if (years >= fmin && years <= fmax) {
            i["price"] = fprice;
            i["age"] = years;
          }

          if (years >= smin && years <= smax) {
            i["price"] = sprice;
            i["age"] = years;
          }

          i[event.target.name] = event.target.value;
        }
      }
      return i;
    });

    setFields(newInputFields);

    sessionStorage.setItem("fields", JSON.stringify(newInputFields));
  };

  const submitForm = (e: any) => {
    const query_string = window.location.search;
    const parsed_qs = parse_query_string(query_string);

    sessionStorage.setItem("agentID", JSON.stringify(parsed_qs.agent));

    setIsRequiredDate(true);
    e.preventDefault();
    if (firstName == "" && lastName == "" && mobile == "") {
      //setFnEmpty(!fnEmpty);

      setError({
        firstName: true,
        lastName: true,
        mobile: true,
      });
    } else if (firstName == "" && lastName == "") {
      setError({
        firstName: true,
        lastName: true,
        mobile: error.mobile,
      });
    } else if (mobile == "" && lastName == "") {
      setError({
        firstName: error.firstName,
        lastName: true,
        mobile: true,
      });
    } else if (mobile == "" && firstName == "") {
      setError({
        firstName: true,
        lastName: error.lastName,
        mobile: true,
      });
    } else if (firstName == "") {
      setError({
        firstName: true,
        lastName: error.lastName,
        mobile: error.mobile,
      });
    } else if (lastName == "") {
      //setLnEmpty(!lnEmpty);
      setError({
        firstName: error.firstName,
        lastName: true,
        mobile: error.mobile,
      });
    } else if (mobile == "") {
      setError({
        firstName: error.firstName,
        lastName: error.lastName,
        mobile: true,
      });
    } else if (isRequiredIN.includes(false)) {
      return;
    } else {
      //nextStep();
    }

    const totalPriceArr: number[] = [];

    fields.map((_e, i) => {
      if (fields[i].idNumber == "") {
        console.log(i);

        const updatisRequiredState = isRequiredIN.map((_item, index) =>
          index == i ? false : true
        );
        // console.log(updatisRequiredState);

        // sessionStorage.setItem("totalPrice", JSON.stringify(totalPrice));

        setisRequiredIN(updatisRequiredState);
      }

      totalPriceArr.push(parseInt(_e.price));
    });

    const totalPrice = totalPriceArr.reduce((a, b) => {
      return a + b;
    }, 0);

    sessionStorage.setItem("totalPrice", JSON.stringify(totalPrice));

    if (
      error.firstName == false &&
      error.lastName == false &&
      error.mobile == false &&
      isMobile == true &&
      //isIDNumber == true &&
      !isRequiredIN.includes(false) &&
      isRequiredDate == true &&
      firstName != "" &&
      lastName != "" &&
      mobile != ""
    ) {
      fields.filter((e, i) => {
        if (e.idNumber === "") {
          // setIsRequiredIN(false);
        } else if (e.idNumber.length < 8) {
          toast.error("ID Number should be greater than 8", {
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (e.date == "") {
          setIsRequiredDate(false);
        } else {
          // setIsRequiredIN(true);
          setIsRequiredDate(true);
          nextStep();
        }
      });
    }
  };

  return (
    <form id="formbox" onSubmit={submitForm} autoComplete="new-password">
      <div className="topsection">
        <ToastContainer rtl={true} />
        <h6>{t("whoIsTravelling")}</h6>
        <p>{t("someBasicDetails")}</p>
      </div>
      <div className="contentbox">
        <h6>{t("howManyPassengers")}</h6>
        <div className="radioSelect" onChange={handlePassengerNumber}>
          <span
            className={
              sessionStorage.getItem("noOfPassenger") == "1" ? "actvRadio" : ""
            }
          >
            <input type="radio" name="passengerNumber" id="" value="1" />
            <label htmlFor="passengerNumber">1</label>
          </span>
          <span
            className={
              sessionStorage.getItem("noOfPassenger") == "2" ? "actvRadio" : ""
            }
          >
            <input type="radio" name="passengerNumber" id="" value="2" />
            <label htmlFor="passengerNumber">2</label>
          </span>
          <span
            className={
              sessionStorage.getItem("noOfPassenger") == "3" ? "actvRadio" : ""
            }
          >
            <input type="radio" name="passengerNumber" id="" value="3" />
            <label htmlFor="passengerNumber">3</label>
          </span>
          <span
            className={
              sessionStorage.getItem("noOfPassenger") == "4" ? "actvRadio" : ""
            }
          >
            <input type="radio" name="passengerNumber" id="" value="4" />
            <label htmlFor="passengerNumber">4</label>
          </span>
          <span
            className={
              sessionStorage.getItem("noOfPassenger") == "5" ? "actvRadio" : ""
            }
          >
            <input type="radio" name="passengerNumber" id="" value="5" />
            <label htmlFor="passengerNumber">5</label>
          </span>
          <div
            className="showmore"
            style={{
              display: showMore ? "flex" : "none",
              flexDirection: "row-reverse",
            }}
          >
            <span
              className={
                sessionStorage.getItem("noOfPassenger") == "6"
                  ? "actvRadio"
                  : ""
              }
            >
              <input type="radio" name="passengerNumber" id="" value="6" />
              <label htmlFor="passengerNumber">6</label>
            </span>
            <span
              className={
                sessionStorage.getItem("noOfPassenger") == "7"
                  ? "actvRadio"
                  : ""
              }
            >
              <input type="radio" name="passengerNumber" id="" value="7" />
              <label htmlFor="passengerNumber">7</label>
            </span>
            <span
              className={
                sessionStorage.getItem("noOfPassenger") == "8"
                  ? "actvRadio"
                  : ""
              }
            >
              <input type="radio" name="passengerNumber" id="" value="8" />
              <label htmlFor="passengerNumber">8</label>
            </span>
          </div>
          <button onClick={handleShowMore}>
            {showMore ? "Less" : t("more")}
          </button>
        </div>

        <input
          autoComplete="off"
          type="text"
          placeholder={t("firstName")}
          name={`fistname`}
          id=""
          onChange={(event) => {
            handleInitialFormData("firstName", event);
          }}
          value={firstName}
        />
        <p
          className="errortext"
          style={{ display: error.firstName == true ? "block" : "" }}
        >
          {t("forgotFirstName")}
        </p>
        <br />
        <input
          autoComplete="off"
          type="text"
          placeholder={t("lastName")}
          name={`lastname`}
          id=""
          onChange={(event) => {
            handleInitialFormData("lastName", event);
          }}
          value={lastName}
        />
        <p
          className="errortext"
          style={{ display: error.lastName == true ? "block" : "" }}
        >
          {t("forgotLastName")}
        </p>
        <br />

        <input
          autoComplete="off"
          type="text"
          placeholder={t("mobilePhone")}
          name={`mobilephone`}
          id=""
          onChange={(event) => {
            handleInitialFormData("mobile", event);
          }}
          value={mobile}
        />
        <p
          className="errortext"
          style={{ display: error.mobile == true ? "block" : "" }}
        >
          {t("forgotMobileNo")}
        </p>
        <p
          className="errortext"
          style={{ display: isMobile == false ? "block" : "" }}
        >
          {t("forgotMobileNo")}
        </p>
        {fields.map((field: any, idx: number) => {
          return (
            <div key={`${field}-${idx}`}>
              <div className="radio-trash mb-3">
                <h6 style={{ fontWeight: "bolder" }}>
                  {t("insured")} {idx + 1}
                </h6>
              </div>
              <div className="radio-trash">
                {sessionStorage.getItem("noOfPassenger") === "1" ? (
                  ""
                ) : (
                  <TrashFill
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRemove(idx)}
                  >
                    X
                  </TrashFill>
                )}
                <div
                  className="radioB"
                  onChange={(event) => {
                    handleChangeInput(idx, event);
                  }}
                >
                  <span>
                    <input
                      type="radio"
                      value="male"
                      name={`insured${idx}`}
                      id=""
                      key={idx}
                      checked={
                        field.insured == "male"
                          ? true
                          : field.insured == "female"
                          ? false
                          : true
                      }
                    />
                    <label htmlFor={`insured${idx}`}>{t("male")}</label>
                  </span>
                  <span>
                    <input
                      type="radio"
                      value="female"
                      name={`insured${idx}`}
                      id=""
                      checked={field.insured == "female" ? true : false}
                    />
                    <label htmlFor="insured">{t("female")}</label>
                  </span>
                </div>
              </div>
              <br />
              <input
                autoComplete="off"
                type="text"
                placeholder={t("idNumber")}
                name={`idNumber`}
                value={field.idNumber}
                minLength={8}
                id=""
                onChange={(event) => {
                  handleChangeInput(idx, event);
                }}
              />
              <p
                className="errortext"
                style={{ display: isIDNumber[idx] == false ? "block" : "" }}
              >
                {t("onlyNo")}
              </p>
              <p
                className="errortext"
                style={{ display: isRequiredIN[idx] == false ? "block" : "" }}
              >
                {t("idRequired")}
              </p>

              <input
                type="date"
                name="date"
                max={moment(todayDate).format("yyyy-MM-DD")}
                id=""
                value={field.date}
                onChange={(event) => {
                  handleChangeInput(idx, event);
                }}
              />
              <p
                className="errortext"
                style={{ display: isRequiredDate == false ? "block" : "" }}
              >
                {t("thisRequired")}
              </p>
            </div>
          );
        })}
        <p className="contDetl">{t("contactDetailsFor")}</p>
        <div className="addingPassenger">
          {sessionStorage.getItem("noOfPassenger") === "8" ? (
            ""
          ) : (
            <>
              <span>{t("addingPassenger")}</span>
              <button
                className="btn add-btn"
                type="button"
                onClick={() => handleAdd()}
              >
                <PlusLg></PlusLg>
              </button>
            </>
          )}
        </div>
        <input
          className="formsubmit"
          type="submit"
          value={`${t("continue")}`}
        />
      </div>
      <div className="next-button-wr">
        {/* <Button onClick={nextStep}>{t("continued")}</Button> */}
      </div>
    </form>
  );
};

export default StepOne;
