import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
function StepSeven({ nextStep, prevStep }: any) {
  const { t, i18n } = useTranslation();
  const [fields, setFields] = useState([]);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [mobile, setmobile] = useState("");
  const [locality, setLocality] = useState("");
  const [postal, setPostal] = useState("");
  const [street, setStreet] = useState("");
  const [houseno, setHouseno] = useState("");
  const handleChangeAddress = (event: any) => {
    sessionStorage.setItem(
      event.target.name,
      JSON.stringify(event.target.value)
    );
    if (event.target.name == "locality") {
      setLocality(event.target.value);
    }
    if (event.target.name == "postal") {
      setPostal(event.target.value);
    }
    if (event.target.name == "street") {
      setStreet(event.target.value);
    }
    if (event.target.name == "houseno") {
      setHouseno(event.target.value);
    }
    if (event.target.name == "firstName") {
      setfirstName(event.target.value);
    }
    if (event.target.name == "lastName") {
      setlastName(event.target.value);
    }
    if (event.target.name == "mobile") {
      setmobile(event.target.value);
    }
  };
  const submitForm = (e: any) => {
    nextStep();
  };

  const handleChangeInput = (id: any, event: any) => {
    const newInputFields = fields.map((i: any, idx: any) => {
      // console.log(i);
      if (id === idx) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });

    setFields(newInputFields);
    sessionStorage.setItem("fields", JSON.stringify(fields));
  };
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("fields") || "");

    const firstNameD = JSON.parse(sessionStorage.getItem("firstName") || "");
    const lastNameD = JSON.parse(sessionStorage.getItem("lastName") || "");
    const mobileD = JSON.parse(sessionStorage.getItem("mobile") || "");

    if (sessionStorage.getItem("locality") != null) {
      setLocality(JSON.parse(sessionStorage.getItem("locality")));
    }
    if (sessionStorage.getItem("postal") != null) {
      setPostal(JSON.parse(sessionStorage.getItem("postal")));
    }
    if (sessionStorage.getItem("street") != null) {
      setStreet(JSON.parse(sessionStorage.getItem("street")));
    }
    if (sessionStorage.getItem("houseno") != null) {
      setHouseno(JSON.parse(sessionStorage.getItem("houseno")));
    }

    setfirstName(firstNameD);
    setlastName(lastNameD);
    setmobile(mobileD);
    setFields(data);
  }, []);
  return (
    <form id="formbox" onSubmit={submitForm} autoComplete="off">
      <a href="#" onClick={prevStep} className="back">
        {t("return")}
      </a>
      <div className="topsection">
        <h6>{t("completeDetail")}</h6>
        <p>{t("lastPayment")}</p>
      </div>
      <div className="contentbox">
        <Col md={12}>
          <input
            required
            type="text"
            placeholder={t("firstName")}
            name="firstName"
            value={firstName}
            id=""
            onChange={(e) => {
              handleChangeAddress(e);
            }}
          />
          <br />
          <input
            required
            type="text"
            placeholder={t("lastName")}
            name="lastName"
            value={lastName}
            onChange={(e) => {
              handleChangeAddress(e);
            }}
            id=""
          />
          <input
            required
            type="text"
            placeholder={t("cellNumber")}
            name="mobile"
            value={mobile}
            onChange={(e) => {
              handleChangeAddress(e);
            }}
            id=""
          />
        </Col>
        {fields.map((f: any, i: any) => (
          <Col md={12} key={i}>
            <h6>
              {t("passengerDetails")} {i + 1} {f.date}
            </h6>

            <br />
            <input
              type="text"
              required
              placeholder={t("nameEnglish")}
              name="englishfirstname"
              id=""
              value={f.englishfirstname}
              onChange={(event: any) => {
                handleChangeInput(i, event);
              }}
            />
            <br />

            <input
              type="text"
              required
              placeholder={t("idNumber")}
              name="idnumber"
              value={f.idNumber}
              id=""
              onChange={(event: any) => {
                handleChangeInput(i, event);
              }}
            />
            <br />

            <br />
            <input
              type="email"
              required
              placeholder={t("mailAddress")}
              name="email"
              id=""
              value={f.email}
              onChange={(event: any) => {
                handleChangeInput(i, event);
              }}
            />
          </Col>
        ))}

        <br />

        <br />
        <br />
        <Col md={12}>
          <input
            type="text"
            required
            placeholder={t("locality")}
            name="locality"
            id=""
            value={locality}
            onChange={(e) => {
              handleChangeAddress(e);
            }}
          />
          <br />
          <input
            type="text"
            required
            placeholder={t("street")}
            name="street"
            id=""
            value={street}
            onChange={(e) => {
              handleChangeAddress(e);
            }}
          />
          <br />
          <Row>
            <Col md={6}>
              <input
                type="text"
                required
                placeholder={t("postalCode")}
                name="postal"
                id=""
                value={postal}
                onChange={(e) => {
                  handleChangeAddress(e);
                }}
              />
            </Col>
            <Col md={6}>
              <input
                required
                type="text"
                placeholder={t("houseNumber")}
                name="houseno"
                id=""
                value={houseno}
                onChange={(e) => {
                  handleChangeAddress(e);
                }}
              />
            </Col>
          </Row>
        </Col>

        <br />
        <input
          className="formsubmit"
          type="submit"
          value={`${t("continued")}`}
        />
      </div>
      <div className="next-button-wr">
        {/* <input className="formsubmit" type="submit" value="Continued" />
        <Button onClick={nextStep}>{t("continued")}</Button> */}
      </div>
    </form>
  );
}

export default StepSeven;
