import { Button, Col, Modal, Row } from "react-bootstrap";
import healthLogo from "../../assets/images/health.png";
import user from "../../assets/images/user.png";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import female from "../../assets/images/female.png";
import shield from "../../assets/images/shield.png";
import pricing from "../../assets/json/pricing.json";
import axios from "axios";
import FormData from "form-data";
import getPrice from "../Utils/PriceService";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function Pregnancy({ closeModal, setPregnancyDone }: any) {
  const { t } = useTranslation();
  const [dates, setDates] = useState({
    releaseDate: "",
    returnDate: "",
  });
  const finalHandleClose = () => {
    closeModal();
    setFinalShow(false);
  };
  const [checked, setChecked] = useState(false);
  const [finalshow, setFinalShow] = useState(false);
  const [fields, setFields] = useState([]);
  const [healthFields, setHealthFields] = useState([]);
  const [pregnancyData, setPregnancyData] = useState("");

  const closeHealthModal = () => {
    sessionStorage.setItem("fields", JSON.stringify(fields));
    const pregnancyArr: any[] = [];
    fields.map((e: any) =>
      // eslint-disable-next-line no-prototype-builtins
      e.hasOwnProperty("pregnancy")
        ? e.pregnancy == true
          ? pregnancyArr.push(e.pregnancy)
          : setPregnancyDone(false)
        : setPregnancyDone(false)
    );

    const PregnancyFormData = new FormData();
    PregnancyFormData.append("item", "pregnancy");

    getPrice(PregnancyFormData, `pregnancy`)
      .then((data: any) => {
        setPregnancyData(`${data}`);
      })
      .catch((error) => {
        setPregnancyData("");
      });

    if (pregnancyArr.includes(true)) {
      setPregnancyDone(true);
      setFinalShow(true);
    }
  };

  const handleChangeInput = (id: any, event: any) => {
    //console.log(event.target);
    // setChecked(!checked);
    const newInputFields = fields.map((i: any, idx: any) => {
      // console.log(i);
      if (id === idx) {
        i[event.target.name] = !i.pregnancy;
        const years = i.age;

        const {
          min: fmin,
          max: fmax,
          price: fprice,
        } = pricing[2].additional.first;
        const {
          min: smin,
          max: smax,
          price: sprice,
        } = pricing[2].additional.second;
        if (years >= fmin && years <= fmax) {
          i["price"] = fprice + i.price;
        }

        if (years >= smin && years <= smax) {
          i["price"] = sprice + i.price;
        }
      }
      return i;
    });

    setFields(newInputFields);

    console.log(fields);
    console.log(fields[0].pregnancy);
    if (fields[0].pregnancy == true) {
      sessionStorage.setItem("pregnancy", "true");
    }
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].pregnancy) {
        fields[i].pregnancyValue = sessionStorage.getItem("pregnancyvalue");
      } else {
        fields[i].pregnancyValue = 0;
      }
    }
    sessionStorage.setItem("fields", JSON.stringify(fields));
  };
  useEffect(() => {
    const data = JSON.parse(sessionStorage.getItem("fields") || "");

    const nf = data.map((e: any) =>
      // eslint-disable-next-line no-prototype-builtins
      e.hasOwnProperty("pregnancy") ? e : Object.assign(e, { pregnancy: false })
    );
    setFields(nf);

    const returnDate = sessionStorage.getItem("returndate");
    const releasedate = sessionStorage.getItem("releasedate");
    setDates({
      releaseDate: releasedate,
      returnDate: returnDate,
    });
  }, []);
  return (
    <>
      <Modal.Body>
        <div className="healthlogo">
          <img src={healthLogo} alt="" />
          <div className="topsection">
            <br />
            <h5>{t("existPregnancy")}</h5>
            <p>{t("pregText")}</p>
          </div>
          <div className="date">
            {moment(dates.releaseDate).format("DD/MM/YYYY")} -{" "}
            {moment(dates.returnDate).format("DD/MM/YYYY")}
          </div>
          <h6>{t("whoTrust")}</h6>
          <br />
          <div className="insured">
            <Row style={{ direction: "rtl" }}>
              {fields.map((e: any, i: number) =>
                e.insured == "female" ? (
                  <Col className="insured" key={i}>
                    <h6>
                      {t("insured")} {i + 1}
                    </h6>
                    <p>{e.date}</p>
                    <div className="hcheckbox">
                      <input
                        type="checkbox"
                        name="pregnancy"
                        id=""
                        checked={e.pregnancy}
                        onChange={(event: any) => {
                          handleChangeInput(i, event);
                        }}
                      />
                      <div
                        className="imgBordr"
                        style={
                          e.pregnancy
                            ? { border: "2px solid red" }
                            : { border: "1px solid #ccc" }
                        }
                      >
                        {e.insured == "female" ? (
                          <img
                            src={female}
                            style={{ margin: "0 -13px;" }}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <p>
                      {t("forEntire")}
                      <br />
                      {t("period")}
                    </p>
                  </Col>
                ) : (
                  ""
                )
              )}
            </Row>
          </div>
          <a href="#" className="underStnd" onClick={closeModal}>
            {t("understoodBut")}
          </a>
          <br />
          <Button className="addCovr" onClick={closeHealthModal}>
            {t("addCover")}&nbsp;<span className="plusIcon">+</span>
          </Button>
          <Modal
            show={finalshow}
            onHide={finalHandleClose}
            className="stepDmodal"
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <img src={shield} alt="" />
              <h6>{t("coverAdded")}</h6>
              <p>{t("policyUpdated")}</p>
              <p>$ {pregnancyData}</p>
            </Modal.Body>
          </Modal>
        </div>
      </Modal.Body>
    </>
  );
}

export default Pregnancy;
