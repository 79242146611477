import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function StepEight({ prevStep }: any) {
  const { t, i18n } = useTranslation();

  const submitForm = (e: any) => {
    e.preventDefault();
    const data = { ...sessionStorage };
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    const urlencoded = new URLSearchParams();
    urlencoded.append("data", JSON.stringify(data));

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };

    fetch(
      "http://travelins.ajjawi.com/backoffice/api/requests/",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  };
  return (
    <form id="formbox" onSubmit={submitForm}>
      <a href="#" onClick={prevStep} className="back">
        {t("return")}
      </a>

      <div className="topsection">
        <p className="price">$16:80</p>
        <h6>{t("remainPay")}</h6>
        <p>{t("anMoment")}</p>
      </div>
      <div className="contentbox">
        <Col md={12}>
          <input
            type="text"
            placeholder={t("debitCardNumber")}
            name="cardno"
            id=""
          />
          <br />

          <Row>
            <Col md={6}>
              <select name="month" id="">
                <option value="" selected disabled>
                  {t("month")}
                </option>
              </select>
            </Col>
            <Col md={6}>
              <select name="year" id="">
                <option value="" selected disabled>
                  {t("year")}
                </option>
              </select>
            </Col>
          </Row>
          <br />
          <input type="text" placeholder="CVV" name="cvv" id="" />
          <br />
        </Col>
        <br />
        <input className="formsubmit" type="submit" value={`${t("submit")}`} />
      </div>
      <div className="next-button-wr">
        {/* <Button >{t("submit")}</Button> */}
      </div>
    </form>
  );
}

export default StepEight;
