export const checkbox = [
  {
    name: "Device1",
    value: 1,
    label: "Mobile Phone",
  },
  {
    name: "Device2",
    value: 1,
    label: "Satellite Phone",
  },
  {
    name: "Device3",
    value: 1,
    label: "Tablet",
  },
  {
    name: "Device4",
    value: 1,
    label: "Camera",
  },
  {
    name: "Device4",
    value: 1,
    label: "GPS Device",
  },
  {
    name: "Device6",
    value: 1,
    label: "A laptop",
  },
];
